import {
  DataTable,
  FormInput,
  DataTableRef,
  HasuraDataTableColumnDef,
  TablePageLayout,
  FormDialog,
  NotificationsContext,
} from '@kirz/mui-admin';
import { PersonAdd } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useContext, useMemo, useRef, useState } from 'react';

import { TutorialWrapper } from 'components/TutorialWrapper';
import { HideProjectSelector } from 'layouts/HideProjectSelector';
import { api } from 'services/api';

export function Users() {
  const { showAlert } = useContext(NotificationsContext);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const tableRef = useRef<DataTableRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      { field: 'telegramId', headerName: 'Telegram Id', flex: 1 },
      { field: 'createdAt', headerName: 'Created at', type: 'date' },
    ],
    [],
  );

  return (
    <TutorialWrapper tutorial="users">
      <HideProjectSelector>
        <TablePageLayout
          title="Users"
          actionContent={
            <Button
              sx={{ ml: 'auto' }}
              variant="contained"
              startIcon={<PersonAdd />}
              onClick={async () => {
                const { bot, authToken } = await api.getTelegramInviteData();
                navigator.clipboard.writeText(
                  `https://t.me/${bot}?start=${authToken}`,
                );
                showAlert(`Invite link copied to clipvoard`);
              }}
            >
              Invite user
            </Button>
          }
        >
          <DataTable
            id="users-table"
            ref={tableRef}
            mode="hasura"
            source="user"
            columns={columns}
            disableRemovedFilter
            editable={{
              onEdit: (row) => {
                setSelectedItem(row);
                setIsEditModalOpened(true);
              },
            }}
            sortBy={{ field: 'id', sort: 'desc' }}
            persistStateMode="query"
            searchFilter={{
              inputProps: {
                placeholder: 'Search by name',
              },
              filter: (search) => ({
                _or: search.flatMap((str) => [
                  { name: { _ilike: `%${str}%` } },
                ]),
              }),
            }}
          />
          <FormDialog
            source="user"
            {...(selectedItem && { entityId: selectedItem.id })}
            open={isEditModalOpened}
            onClose={() => setIsEditModalOpened(false)}
            title={!selectedItem ? 'New user' : 'Edit user'}
            maxWidth="sm"
            onSubmit={() => {
              tableRef.current?.reload();
            }}
          >
            <FormInput name="name" label="Name" md={6} />
            <FormInput name="telegramId" label="Telegram ID" md={6} readOnly />
          </FormDialog>
        </TablePageLayout>
      </HideProjectSelector>
    </TutorialWrapper>
  );
}
