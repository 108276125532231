import { BasePageLayout, ConfigurationContext } from '@kirz/mui-admin';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { CashPlus } from 'mdi-material-ui';
import React, { useContext, useEffect, useState } from 'react';

import { UserContext } from 'contexts/UserContext';
import { HideProjectSelector } from 'layouts/HideProjectSelector';
import { SubscriptionPlan } from 'types/premium';

export const PlanInfo: Record<
  SubscriptionPlan['key'],
  {
    name: string;
    description: string;
    features: string[];
  }
> = {
  bronze: {
    name: 'Basic Plan',
    description:
      'The Basic Plan is perfect for individuals who want to explore our services with essential features. Get started with our basic tools and upgrade as you grow.',
    features: [
      'Access to core features',
      '10GB storage',
      'Email support',
      'Weekly updates',
      'Community access',
    ],
  },
  silver: {
    name: 'Pro Plan',
    description:
      'The Pro Plan is designed for professionals who need advanced features and more flexibility. Enjoy enhanced tools and priority support to help you achieve your goals.',
    features: [
      'Everything in Basic Plan',
      '100GB storage',
      'Priority email support',
      'Daily updates',
      'Access to premium templates',
      'Advanced analytics',
    ],
  },
  gold: {
    name: 'Premium Plan',
    description:
      'The Premium Plan offers the ultimate experience with all our features unlocked. Ideal for businesses and power users who need top-tier performance and support.',
    features: [
      'Everything in Pro Plan',
      'Unlimited storage',
      '24/7 phone and email support',
      'Real-time updates',
      'Customizable templates',
      'Dedicated account manager',
      'VIP community access',
    ],
  },
};

export function Subscription() {
  const { hasura } = useContext(ConfigurationContext);
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const { user } = useContext(UserContext);
  const activeSubscription =
    user.subscriptionId && plans.find((x) => x.id === user.subscriptionId);

  const handleActivate = async (plan: SubscriptionPlan) => {
    window.location.href = `/api/v1/payments/subscribe?plan_id=${plan.id}`;
  };

  useEffect(() => {
    (async () => {
      const items = await hasura.request({
        type: 'query',
        source: 'subscriptionPlan',
        selection: 'id key price gptTokensPerMonth',
        orderBy: { id: 'ASC' },
      });

      setPlans(items);
    })();
  }, []);

  if (!plans.length) {
    return (
      <HideProjectSelector>
        <BasePageLayout title="">
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        </BasePageLayout>
      </HideProjectSelector>
    );
  }

  return (
    <HideProjectSelector>
      <BasePageLayout title="">
        <Box flexGrow={1} p={4}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack spacing={0.4}>
              <Typography sx={{ fontSize: 30, fontWeight: '400' }}>
                Current Plan:{' '}
                <b
                  style={{
                    ...(!activeSubscription &&
                      !user.isTrialActive && {
                        color: 'red',
                      }),
                  }}
                >
                  {activeSubscription
                    ? PlanInfo[activeSubscription.key].name
                    : user.isTrialActive
                    ? 'Trial'
                    : 'None'}
                </b>
              </Typography>
              {(() => {
                if (!activeSubscription) {
                  const diff =
                    user.trialDuration -
                    dayjs().diff(user.trialStartedAt, 'hours');

                  return (
                    <Typography sx={{}}>
                      Remaining Time:{' '}
                      {diff > 24
                        ? `${Math.floor(diff / 24)} days`
                        : diff > 0
                        ? `${diff} hours`
                        : 'ended'}{' '}
                      (till{' '}
                      {dayjs(user.trialStartedAt)
                        .add(user.trialDuration, 'hours')
                        .format('DD.MM.YYYY')}
                      )
                    </Typography>
                  );
                } else if (user.isTrialActive) {
                  const nextRebillDate = dayjs(user.lastPurchaseDate!).add(
                    1,
                    'month',
                  );
                  const diff = dayjs(nextRebillDate).fromNow();

                  return (
                    <Typography variant="subtitle1">
                      Next rebill: {diff}
                      (on {dayjs(nextRebillDate).format('DD.MM.YYYY')})
                    </Typography>
                  );
                } else {
                  return (
                    <Typography variant="subtitle1">
                      Please consider subscription to continue use of our
                      platform
                    </Typography>
                  );
                }
              })()}
            </Stack>
            <Stack direction="column" alignItems="flex-end" spacing={0.5}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography sx={{ fontSize: 22 }}>GPT-tokens: </Typography>
                <Typography sx={{ fontSize: 28, fontWeight: '600' }}>
                  {user.availableGptTokens.toLocaleString()}
                </Typography>
              </Stack>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<CashPlus />}
              >
                Add more
              </Button>
            </Stack>
          </Stack>

          <Grid container spacing={2} mt={2}>
            {plans.map((plan) => {
              const planInfo = PlanInfo[plan.key];

              return (
                <Grid
                  item
                  xs={12}
                  md={4}
                  key={plan.id}
                  sx={{ display: 'flex' }}
                >
                  <Card
                    sx={{
                      boxShadow: '0px 2px 10px 1px rgba(100, 116, 139, 0.15)',
                      flex: 1,
                      display: 'flex',
                    }}
                  >
                    <CardContent
                      sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                    >
                      <Typography variant="h5">{planInfo.name}</Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {planInfo.description}
                      </Typography>
                      <Box
                        component="ul"
                        sx={{
                          listStyleType: 'inherit',
                          mb: 3,
                          lineHeight: 1.9,
                        }}
                      >
                        {planInfo.features.map((feature) => (
                          <Box key={feature} component="li">
                            {feature}
                          </Box>
                        ))}
                        <Box component="li">
                          <b>{plan.gptTokensPerMonth.toLocaleString()}</b>{' '}
                          GPT-tokens/month
                        </Box>
                      </Box>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mt: 'auto' }}
                      >
                        <Typography
                          sx={{
                            fontSize: 28,
                            display: 'flex',
                            alignItems: 'baseline',
                            fontWeight: '600',
                          }}
                        >
                          {plan.price}₽
                          <Typography
                            sx={{ fontSize: 15, fontWeight: '400' }}
                            component="span"
                          >
                            /month
                          </Typography>
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleActivate(plan)}
                        >
                          Activate
                        </Button>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </BasePageLayout>
    </HideProjectSelector>
  );
}
