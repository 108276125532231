import { NotificationsContext, useNavigate } from '@kirz/mui-admin';
import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { AppBar } from 'components/AppBar';
import { NavigationDrawer } from 'components/NavigationDrawer';
import { AppStateProvider } from 'contexts/AppStateContext';
import { UserContext } from 'contexts/UserContext';

export function DefaultLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isUserLoading, user } = useContext(UserContext);
  const { showAlert } = useContext(NotificationsContext);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  useEffect(() => {
    if (
      isUserLoading ||
      location.pathname.startsWith('/subscription') ||
      location.pathname.startsWith('/settings') ||
      user.isTrialActive ||
      user.isSubscriptionActive
    ) {
      return;
    }

    showAlert('Active Subscription required', 'error');
    navigate('/subscription');
  }, [location.pathname, isUserLoading, user]);

  return (
    <AppStateProvider>
      <Box sx={{ display: 'flex' }}>
        <NavigationDrawer
          isDrawerOpened={isDrawerOpened}
          setIsDrawerOpened={setIsDrawerOpened}
        />
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            paddingTop: { xs: '56px', md: '64px' },
            maxHeight: '100vh',
            position: 'relative',
            overflowX: 'hidden',
          }}
        >
          <AppBar
            onDrawerToggleClick={() => setIsDrawerOpened(!isDrawerOpened)}
          />
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              width: '100%',
              overflowY: 'auto',
            }}
          >
            <Box
              component="main"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                p: { xs: 2, lg: 3 },
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </AppStateProvider>
  );
}
