import axios, { AxiosError, AxiosResponse } from 'axios';
import { Client, createClient } from 'graphql-ws';

import { LoginPayload, LoginResponse } from 'types/auth';
import { User } from 'types/user';

export class Api {
  constructor(
    public readonly client = axios.create({ baseURL: '/api/v1' }),
    public readonly hasuraClient = axios.create({
      baseURL: '/v1/graphql',
    }),
    private hasuraWsClient: Client | null = null,
    private readonly hasuraMutationClient = axios.create({
      baseURL: '/api/v1/hasura/mutation',
    }),
  ) {
    const responseInterceptor = (response: AxiosResponse<any, any>) => {
      if (response.data?.errors?.length) {
        if (
          response.data?.errors[0]?.message?.includes(`not found in type: '`) &&
          !window.location.pathname.includes('/login')
        ) {
          window.location.href = '/login';
        }
      }
      return response;
    };

    const errorInterceptor = (error: AxiosError) => {
      if (
        !window.location.pathname.includes('/login') &&
        (error.response?.status === 403 || error.response?.status === 401)
      ) {
        window.location.href = '/login';
      }
      return Promise.reject(error);
    };

    this.client.interceptors.response.use(
      responseInterceptor,
      errorInterceptor,
    );

    this.hasuraClient.interceptors.response
      .use
      // responseInterceptor,
      // errorInterceptor,
      ();

    this.hasuraMutationClient.interceptors.response.use(
      responseInterceptor,
      errorInterceptor,
    );
  }

  get instance() {
    return this.client;
  }

  get hasuraClientInstance() {
    return this.hasuraClient;
  }

  get hasuraWsClientInstance() {
    return this.hasuraWsClient;
  }

  get hasuraMutationClientInstance() {
    return this.hasuraMutationClient;
  }

  initializeWsConnection() {
    this.hasuraWsClient = createClient({
      url:
        process.env.NODE_ENV === 'development'
          ? 'ws://localhost:8082/v1/graphql'
          : `wss://${window.location.host}/v1/graphql`,
      shouldRetry: () => true,
    });
  }

  async getMe() {
    try {
      const { data } = await this.client.get<User>('/auth');
      return data;
    } catch {
      return undefined;
    }
  }

  async getSettings() {
    const {
      data: {
        data: { projects, companies },
      },
    } = await this.hasuraClientInstance.post<{
      data: {
        projects: any[];
        companies: any[];
      };
    }>('', {
      query: `
        {
          projects: project {
            id name createdAt
          }
          companies: company {
            publicationDateFrom
            publicationDateTo
          }
        }`
        .replace(/\n/g, ' ')
        .replace(/ +/g, ' ')
        .trim(),
    });

    return {
      projects,
      publicationDateFrom: companies[0]?.publicationDateFrom,
      publicationDateTo: companies[0]?.publicationDateTo,
    };
  }

  async getTelegramAuthData(): Promise<{
    authToken: string;
    bot: string;
  }> {
    const { data } = await this.client.get('auth/telegram');

    return data;
  }

  async getTelegramInviteData(): Promise<{
    authToken: string;
    bot: string;
  }> {
    const { data } = await this.client.get('auth/telegram-invite');

    return data;
  }

  async login(payload: LoginPayload): Promise<LoginResponse> {
    const { data } = await this.client.post('/auth/login', payload);

    return data;
  }

  async logout(): Promise<void> {
    await this.client.post('/auth/logout');
  }

  async authWorker(payload: {
    workerId: number;
    code?: string;
    password?: string;
  }): Promise<{
    errorCode:
      | 'CODE_REQUIRED'
      | 'PASSWORD_REQUIRED'
      | 'INVALID_CODE'
      | 'INVALID_PASSWORD'
      | null;
  }> {
    const { data } = await this.client.post('/tdlib/auth', payload);
    return data;
  }

  async searchChannel(query: string) {
    const {
      data: { chat },
    } = await this.client.post('/tdlib/channels/search', {
      query,
    });

    return chat;
  }

  async downloadFile(fileId: number, tdlibClientId: string) {
    const { data } = await this.client.post(
      `/tdlib/clients/${tdlibClientId}/files/${fileId}/download`,
    );

    return data;
  }
}

export const api = new Api();
