import { Box, Button, Typography } from '@mui/material';
import { TextBoxPlusOutline } from 'mdi-material-ui';
import React from 'react';

import Icon1 from 'assets/icons/important.svg';
import Icon2 from 'assets/icons/postsIcon.svg';

import { TutorialDefinition } from '../types';

export const posts = (({ t, language }) => ({
  steps: [
    {
      title: '',
      text: (
        <Box sx={{ display: 'grid' }}>
          <Box
            component="img"
            src={Icon1}
            sx={{ width: '100px', height: '100px', margin: '0 auto' }}
          />
          <br />
          <Typography component="span">
            Мы покажем несколько таких окошек, чтобы познакомить тебя с
            функционалом платформы.
          </Typography>
          <br />
          <Typography component="span">
            Ты можешь нажать &laquo;{t('Skip tutorial')}&raquo; в левом нижнем
            углу, но лучше прочитай. Тут много классных функий — так будет проще
            разобраться😏
          </Typography>
        </Box>
      ),
    },
    {
      title: '',
      text: (
        <Box sx={{ display: 'grid' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component="img"
              src={Icon2}
              sx={{ width: '50px', height: '50px' }}
            />
            <Typography
              sx={{ fontSize: '2em', fontWeight: 'bold', color: '#10b981' }}
            >
              &nbsp;&nbsp;Посты
            </Typography>
          </Box>

          <br />
          <Typography component="span">
            Это основной раздел. Здесь происходит вся работа с публикациями.
          </Typography>
          <br />
          <Typography component="span">
            Откуда беруться посты?
            <ul className="tutorial-ul">
              <li>
                Пост можно просто написать самостоятельно. Кнопка создания поста
                в правом верхнем углу&nbsp;&nbsp;&nbsp;
                <Button
                  sx={{ ml: 'auto' }}
                  variant="contained"
                  startIcon={<TextBoxPlusOutline />}
                  onClick={() => {
                    // createEmptyPost();
                  }}
                >
                  {t('Add post')}
                </Button>
              </li>
              <li>
                Посты можно получать из <a href="/sources">Источников</a>
              </li>
              <li>
                Если ты работаешь с командой и у тебя есть
                копирайтер/редактор/помощник — <a href="/users">пригласи</a> их{' '}
                на платформу. Вы сможете работать над постами совместно
              </li>
            </ul>
          </Typography>
          <Typography component="span">
            Но для публикации постов нужно сначала{' '}
            <a href="/channels">добавить канал</a>
          </Typography>
        </Box>
      ),
    },
  ],
  lastStepButtonText: t('Go to Channels'),
  actionHref: '/channels',
})) as TutorialDefinition;
