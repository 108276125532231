import { useNavigate } from '@kirz/mui-admin';
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTranslation } from 'hooks/useTranslation';
import { api } from 'services/api';
import { User } from 'types/user';

import { SettingsContext, SettingsContextType } from './SettingsContext';

type UserContextType = {
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  isUserLoading: boolean;
  initialize: () => Promise<void>;
  logout: () => Promise<void>;
  hasPermission: (permission: string) => boolean;
};

export const UserContext = createContext<UserContextType>({
  isUserLoading: true,
} as any);

export function UserContextProvider({ children }: PropsWithChildren) {
  const [isUserLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [settings, setSettings] = useState<SettingsContextType>({} as any);
  const { switchLanguage } = useTranslation();

  const navigate = useNavigate();

  const fetchSettings = useCallback(async () => {
    try {
      const settingsData = (await api.getSettings()) as any;
      setSettings(settingsData);
    } catch {
      // no op
    }
  }, []);

  const initialize = useCallback(async () => {
    const [loggedUser] = await Promise.all([
      api.getMe().then(async (x) => {
        await fetchSettings();
        return x;
      }),
      new Promise((resolve) => {
        setTimeout(resolve, 400);
      }),
    ]);

    if (loggedUser) {
      setUser({
        ...loggedUser,
        isSuperAdmin: loggedUser.companyId === 1,
      });
    } else {
      setUser(loggedUser);
    }

    setIsLoading(false);

    api.initializeWsConnection();

    const isLoginPage = window.location.href.includes('/login');
    if (loggedUser && isLoginPage) {
      navigate('/');
      return;
    }

    if (!loggedUser) {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(async () => {
    await api.logout();
    setUser(undefined);
  }, []);

  const hasPermission = useCallback(
    (permission: string) => {
      return true;
    },
    [user],
  );

  useEffect(() => {
    if (!user?.language) {
      return;
    }

    switchLanguage(user.language as any);
  }, [user?.language, switchLanguage]);

  const contextData = useMemo<UserContextType>(
    () => ({
      user: user!,
      isUserLoading,
      logout,
      initialize,
      hasPermission,
      setUser: setUser as any,
    }),
    [user, isUserLoading, initialize, hasPermission],
  );

  const settingsContextData = useMemo<SettingsContextType>(
    () => ({
      ...settings,
      reloadSettings: fetchSettings,
    }),
    [settings, fetchSettings],
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <UserContext.Provider value={contextData as UserContextType}>
      <SettingsContext.Provider value={settingsContextData!}>
        {children}
      </SettingsContext.Provider>
    </UserContext.Provider>
  );
}
