import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const defaultLanguage = localStorage.getItem('lang') ?? 'ru';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require('./en.json'),
    },
    ru: {
      translation: require('./ru.json'),
    },
  },
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
