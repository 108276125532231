import {
  AutocompleteInput,
  FormGetter,
  AutocompleteInputProps,
} from '@kirz/mui-admin';
import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

export function DealFormFunnelAutocomplete({
  ...props
}: Partial<AutocompleteInputProps>) {
  const { getValues, setValue } = useFormContext();
  const fetchedFunnelsRef = useRef<any[]>([]);

  const setDefaultFunnelStageId = (funnelId: number) => {
    setValue(
      'funnelStageId',
      fetchedFunnelsRef.current.find((x) => x.id === funnelId)?.stages[0]?.id ??
        null,
      {
        shouldDirty: true,
        shouldTouch: true,
      },
    );
  };

  return (
    <>
      <FormGetter
        names={['funnelId']}
        onChange={({ funnelId }, prevValue) => {
          if (!prevValue?.funnelId) {
            return;
          }

          setDefaultFunnelStageId(funnelId);
        }}
      />

      <AutocompleteInput
        label="Funnel"
        name="funnelId"
        mode="hasura"
        source="funnel"
        selection="id name stages { id }"
        itemText="name"
        required
        {...(props as any)}
        onFetch={(items) => {
          fetchedFunnelsRef.current = items;

          setTimeout(() => {
            const funnelId = getValues('funnelId');

            if (funnelId && !getValues('funnelStageId')) {
              setDefaultFunnelStageId(funnelId);
            }
          }, 100);

          return items;
        }}
      />
    </>
  );
}
