import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Paper,
  Container,
  Alert,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation as useReactI18NextTranslation } from 'react-i18next';

import chat1 from 'assets/onboarding/chat1.png';
import chat1ru from 'assets/onboarding/chat1_ru.png';
import chat2 from 'assets/onboarding/chat2.png';
import chat2ru from 'assets/onboarding/chat2_ru.png';
import { useTranslation } from 'hooks/useTranslation';

import { EditProjectDialog } from './EditProjectDialog';
import { LanguageToggler } from './LanguageToggler';

function getStepContent(step: number, t: any, i18n: any) {
  switch (step) {
    case 0:
      return (
        <Box>
          <Typography variant="h5" gutterBottom>
            {t('Welcome to PROD Platform!')}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className="new-line-translation"
          >
            {t('Onboarding step-1 description')}
          </Typography>
          <img
            style={{ width: '80%', margin: '0 0 0 10%' }}
            src={chat1}
            hidden={i18n.language === 'ru'}
          />
          <img
            style={{ width: '80%', margin: '0 0 0 10%' }}
            src={chat1ru}
            hidden={i18n.language === 'en'}
          />
          {/* <Box
            component="img"
            src="https://via.placeholder.com/400x200"
            alt="Welcome"
            sx={{ width: '100%', borderRadius: 2, marginTop: 2 }}
          /> */}
        </Box>
      );
    case 1:
      return (
        <Box>
          <Typography variant="h5" gutterBottom>
            {t('Our Features')}
          </Typography>
          {/* <Typography variant="body1" gutterBottom>
            Customize your experience to suit your needs. Here are some of the
            features of our application:
          </Typography> */}
          <img
            style={{ width: '80%', margin: '0 0 0 10%' }}
            src={chat2}
            hidden={i18n.language === 'ru'}
          />
          <img
            style={{ width: '80%', margin: '0 0 0 10%' }}
            src={chat2ru}
            hidden={i18n.language === 'en'}
          />
          {/* <Box
            component="img"
            src="https://via.placeholder.com/400x200"
            alt="Setup"
            sx={{ width: '100%', borderRadius: 2, marginTop: 2 }}
          /> */}
        </Box>
      );
    case 2:
      return (
        <Box>
          <Typography variant="h5" gutterBottom>
            {t("You're All Set!")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('Onboarding step-2 description')}
          </Typography>
          {/* <Box
            component="img"
            src="https://via.placeholder.com/400x200"
            alt="Complete"
            sx={{ width: '100%', borderRadius: 2, marginTop: 2 }}
          /> */}
        </Box>
      );
    default:
      return 'Unknown step';
  }
}

export function OnboardingPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [isNewProjectDialogOpened, setIsNewProjectDialogOpened] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = () => {
    setIsLoading(true);
    setIsNewProjectDialogOpened(true);
  };

  const { t } = useTranslation();
  const { i18n } = useReactI18NextTranslation();

  document.body.style.overflow = 'scroll';

  const steps = [t('Welcome'), t('Our Features'), t('Complete')];

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="sm" sx={{ padding: 2 }}>
        <Paper elevation={3} sx={{ padding: 4, position: 'relative' }}>
          <LanguageToggler sx={{ position: 'absolute', left: 10, top: 4 }} />

          <Stepper activeStep={activeStep} alternativeLabel sx={{ mt: 1 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 2 }}>
            {getStepContent(activeStep, t, i18n)}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t('Back')}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep === steps.length - 1 ? (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={handleFinish}
                  loading={isLoading}
                >
                  {t('Finish')}
                </LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {t('Continue')}
                </Button>
              )}
            </Box>
          </Box>
        </Paper>
      </Container>
      <EditProjectDialog
        open={isNewProjectDialogOpened}
        onSubmit={async (item) => {
          window.location.reload();
        }}
        onClose={async () => {
          setIsLoading(false);
          setIsNewProjectDialogOpened(false);
          document.body.style.overflow = 'hidden';
        }}
        formHeader={
          <Alert severity="info">
            <Typography variant="body1" gutterBottom>
              {t('Create project description')}
            </Typography>
          </Alert>
        }
      />
    </Box>
  );
}
