import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useMemo, useRef } from 'react';

import { TutorialWrapper } from 'components/TutorialWrapper';
import { useTranslation } from 'hooks/useTranslation';
import { HideProjectSelector } from 'layouts/HideProjectSelector';

export function Contacts() {
  const tableRef = useRef<DataTableExRef>(null);
  const { t } = useTranslation();

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: t('ID'),
        minWidth: 40,
        width: 60,
      },
      {
        field: 'userUniqueName',
        headerName: t('Username'),
        flex: 1,
      },
      {
        field: 'firstName',
        headerName: t('First name'),
        flex: 1,
      },
      {
        field: 'lastName',
        headerName: t('Last name'),
        flex: 1,
      },
      { field: 'createdAt', headerName: t('Created at'), type: 'date' },
    ],
    [],
  );

  return (
    <TutorialWrapper tutorial="contacts">
      <HideProjectSelector>
        <TablePageLayout
          title={t('Contacts')}
          actionContent={
            <Button
              sx={{ ml: 'auto' }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                tableRef.current?.openFormDialog();
              }}
            >
              {t('Add contact')}
            </Button>
          }
        >
          <DataTableEx
            id="contacts-table"
            ref={tableRef}
            source="client"
            columns={columns}
            disableRemovedFilter
            sortBy={{ field: 'id', sort: 'desc' }}
            persistStateMode="query"
            formTitle={(isNew) =>
              isNew ? t('New contact') : t('Edit contact')
            }
            searchFilter={{
              inputProps: {
                placeholder: t('Search by username'),
              },
              filter: (search) => ({
                _or: search.flatMap((str) => [
                  {
                    _or: [{ userUniqueName: { _ilike: `%${str}%` } }],
                  },
                ]),
              }),
            }}
            formDialogProps={{
              formSubmitterProps: {
                async preSubmit({ id, ...item }) {
                  const { firstName, lastName, userUniqueName } = item;
                  return {
                    firstName,
                    lastName,
                    userUniqueName: `@${userUniqueName
                      .replace(`https://t.me/`, '')
                      .replace(`http://t.me/`, '')
                      .replace(/@/g, '')
                      .trim()}`,
                  };
                },
              },
            }}
          >
            <FormInput
              name="userUniqueName"
              label={t('Username')}
              required
              placeholder={t(
                'Enter full username (e.g. @albernov or https://t.me/albernov)',
              )}
            />
            <FormInput name="firstName" label={t('First name')} />
            <FormInput name="lastName" label={t('Last name')} />
          </DataTableEx>
        </TablePageLayout>
      </HideProjectSelector>
    </TutorialWrapper>
  );
}
