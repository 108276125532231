import { Box } from '@mui/material';
import React from 'react';

export function Separator() {
  return (
    <Box
      component="span"
      sx={{
        background: 'rgba(29, 28, 29, 0.13)',
        flexShrink: 0,
        alignSelf: 'center',
        width: '1px',
        height: '20px',
        marginX: '-2px',
      }}
    />
  );
}
