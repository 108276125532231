import { Alert, Box, Typography } from '@mui/material';
import React from 'react';

import ChParamsRu from 'assets/icons/chParams_ru.jpg';
import Icon3 from 'assets/icons/channelsIcon.svg';
import { EditChannelDialog } from 'components/EditChannelDialog';

import { TutorialDefinition } from '../types';
import { wrapActionDialog } from '../wrapActionDialog';

export const channels = (({ t, language }) => ({
  steps: [
    {
      title: '',
      text: (
        <Box sx={{ display: 'grid' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component="img"
              src={Icon3}
              sx={{ width: '50px', height: '50px' }}
            />
            <Typography
              sx={{ fontSize: '2em', fontWeight: 'bold', color: '#10b981' }}
            >
              &nbsp;&nbsp;Каналы
            </Typography>
          </Box>

          <br />
          <Typography component="span">
            Чтобы делать публикации, нужно подключить твой TG-канал к платформе
            с помощью бота
          </Typography>
          <br />
          <Typography component="span">
            <ul className="tutorial-ul">
              <li>
                Добавь в список администраторов канала нашего бота{' '}
                <b>@ProdPlatform_bot</b>
              </li>
              <li>
                Выдай боту права на:
                <ul>
                  <li>управление сообщениями</li>
                  <li>добавление подписчиков</li>
                  <li>
                    назначение администраторов
                    <Box
                      component="img"
                      src={ChParamsRu}
                      sx={{ width: '250px', height: 'auto', margin: '0 auto' }}
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography>
            Когда добавишь бота в админы — жми &laquo;Создать канал&raquo;
          </Typography>
        </Box>
      ),
    },
  ],
  actionDialog: wrapActionDialog({
    dialog: EditChannelDialog as any,
    topSlot: (
      <Alert severity="info">
        Aenean et tortor at risus viverra adipiscing at in. Nunc pulvinar sapien
        et ligula ullamcorper malesuada.
      </Alert>
    ),
  }),
  lastStepButtonText: t('Create channel'),
})) as TutorialDefinition;
