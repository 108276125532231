import { useState, useEffect, useMemo } from 'react';

export function useDelayedMemo<T>(value: T, delay: number) {
  const [delayedValue, setDelayedValue] = useState<T | null>(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDelayedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  const memoizedValue = useMemo(() => delayedValue, [delayedValue]);

  return memoizedValue;
}
