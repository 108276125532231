import {
  ConfigurationProvider as MuiAdminConfigurationProvider,
  NavigationContextProvider,
} from '@kirz/mui-admin';
import React, { useContext, useEffect } from 'react';
import {
  Routes as DomRoutes,
  Navigate,
  Route,
  useNavigate,
} from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';

import { RouteGuard } from 'components/RouteGuard';
import { SplashLoader } from 'components/SplashLoader';
import { Configuration } from 'constants/configuration';
import { UserContext } from 'contexts/UserContext';
import { DefaultLayout } from 'layouts/DefaultLayout';
import { AutoFilter } from 'pages/AutoFilter';
import { Channels } from 'pages/Channels';
import { Companies } from 'pages/Companies';
import { Contact } from 'pages/Contact';
import { Contacts } from 'pages/Contacts';
import { Deal } from 'pages/Deal';
import { Deals } from 'pages/Deals';
import { Funnel } from 'pages/Funnel';
import { Funnels } from 'pages/Funnels';
import { Login } from 'pages/Login';
import { Post } from 'pages/Post';
import { Posts } from 'pages/Posts';
import { Projects } from 'pages/Projects';
import { Settings } from 'pages/Settings';
import { Sources } from 'pages/Sources';
import { Subscription } from 'pages/Subscription';
import { Users } from 'pages/Users';
import { Workers } from 'pages/Workers';

export default function App() {
  const { isUserLoading, user, hasPermission } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoading || user) {
      return;
    }

    navigate('/login');
  }, [isUserLoading, user]);

  const routes = [
    {
      path: 'posts',
      children: (
        <>
          <Route index element={<Posts />} />
        </>
      ),
    },
    {
      path: 'projects/:projectId/posts/:postId',
      element: <Post />,
    },
    {
      path: 'sources',
      element: <Sources />,
    },
    {
      path: 'auto-filter',
      element: <AutoFilter />,
    },
    {
      path: 'channels',
      element: <Channels />,
    },
    {
      path: 'deals',
      children: (
        <>
          <Route index element={<Deals />} />
          <Route path=":id" element={<Deal />} />
        </>
      ),
    },
    {
      path: 'contacts',
      children: (
        <>
          <Route index element={<Contacts />} />
          <Route path=":id" element={<Contact />} />
        </>
      ),
    },
    {
      path: 'users',
      element: <Users />,
    },
    {
      path: 'projects',
      element: <Projects />,
    },
    {
      path: 'funnels',
      children: (
        <>
          <Route index element={<Funnels />} />
          <Route path=":funnelId" element={<Funnel />} />
        </>
      ),
    },
    {
      path: 'subscription',
      element: <Subscription />,
    },
    {
      path: 'settings',
      element: <Settings />,
    },
    {
      path: 'companies',
      element: <Companies />,
    },
    ...(user?.isSuperAdmin
      ? [
          {
            path: 'workers',
            element: <Workers />,
          },
        ]
      : []),
  ] as {
    path: string;
    permission?: string | string[];
    children?: React.ReactNode;
    element?: React.ReactNode;
  }[];

  const defaultRoute =
    routes.find(
      (x) =>
        !x.permission ||
        (typeof x.permission === 'string'
          ? hasPermission(x.permission)
          : !!x.permission.find((y) => hasPermission(y))),
    )?.path || '/';

  return (
    <MuiAdminConfigurationProvider
      {...Configuration((user?.language ?? 'en') as any).muiAdmin}
    >
      <NavigationContextProvider>
        <DomRoutes>
          <Route path="login" element={<Login />} />
          {user && (
            <>
              <Route path="/" element={<DefaultLayout />}>
                {routes.map((route) => {
                  const content = route.children || (
                    <Route index element={route.element} />
                  );

                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<RouteGuard permission={route.permission} />}
                    >
                      {content}
                    </Route>
                  );
                })}
                <Route index element={<Navigate to={defaultRoute} />} />
              </Route>
              <Route path="*" element={<Navigate to={defaultRoute} />} />
            </>
          )}
        </DomRoutes>
        <SplashLoader visible={isUserLoading} />
      </NavigationContextProvider>
      <YMInitializer accounts={[97624459]} options={{ webvisor: true }} />
    </MuiAdminConfigurationProvider>
  );
}
