import {
  SelectInput,
  SubmitButton,
  Form,
  Grid,
  PromiseUtils,
} from '@kirz/mui-admin';
import { Telegram } from '@mui/icons-material';
import { Alert, Container, Paper, Stack, Typography } from '@mui/material';
import React, { useContext, useRef, useState } from 'react';

import { LanguageToggler } from 'components/LanguageToggler';
import { UserContext } from 'contexts/UserContext';
import { useTranslation } from 'hooks/useTranslation';
import { api } from 'services/api';
import { LoginError, LoginPayload, UserIdentity } from 'types/auth';

export function Login() {
  const { initialize } = useContext(UserContext);
  const { t } = useTranslation();
  const [loginStep, setLoginStep] = useState<'initial' | 'identity'>('initial');
  const [identities, setIdentities] = useState<UserIdentity[]>([]);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const authTokenRef = useRef<string>();

  const tryToLogin = async (payload: LoginPayload) => {
    const { success, errorCode, errorData } = await api.login(payload);

    if (success) {
      await initialize!();
      return true;
    }

    if (errorCode === LoginError.IDENTITY_NOT_FOUND) {
      return false;
    }

    setErrorMessage(null);
    setInfoMessage(null);

    switch (errorCode) {
      case LoginError.IDENTITY_SELECTION_REQUIRED: {
        setLoginStep('identity');
        setIdentities(errorData.identities as UserIdentity[]);
        setInfoMessage('You assigned to multiple companies');
        break;
      }
    }

    return true;
  };

  const handleSubmit = async (payload: LoginPayload) => {
    if (authTokenRef.current) {
      await tryToLogin({
        ...payload,
        authToken: authTokenRef.current,
      });
      return;
    }

    const { authToken, bot } = await api.getTelegramAuthData();
    window.open(`https://t.me/${bot}?start=${authToken}`, '_blank');

    authTokenRef.current = authToken;

    await new Promise<void>(async (resolve) => {
      let success = false;
      do {
        success = await tryToLogin({ authToken });
        await PromiseUtils.wait(2000);
      } while (!success);

      resolve();
    });
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}
    >
      <Paper
        elevation={16}
        sx={{ flex: 1, p: 4, width: '100%', position: 'relative' }}
      >
        <LanguageToggler sx={{ position: 'absolute', left: 10, top: 4 }} />
        <Stack
          alignItems="center"
          spacing={1}
          sx={{ position: 'relative', pt: 2 }}
        >
          <Typography variant="h4">PROD Platform</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('AI-powered control panel for TG-creators')}
          </Typography>
          <Container maxWidth="xs">
            <Form
              sx={{ alignSelf: 'stretch', pt: 3 }}
              onSubmit={handleSubmit}
              spacing={3}
              shouldUnregister={false}
              dirtySubmit={false}
            >
              {infoMessage && (
                <Grid xs={12}>
                  <Alert severity="info">{infoMessage}</Alert>
                </Grid>
              )}
              {errorMessage && (
                <Grid xs={12}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Grid>
              )}
              {loginStep === 'identity' && (
                <SelectInput
                  label="Company"
                  required
                  name="identityId"
                  items={identities.map((x) => ({
                    value: x.id,
                    text: `${x.companyName} [${x.userName}]`,
                  }))}
                />
              )}
              <SubmitButton
                variant="contained"
                size="large"
                grid
                fullWidth
                xs={12}
              >
                <Telegram sx={{ mr: 1 }} />
                {t('Sign in with Telegram')}
              </SubmitButton>
            </Form>
          </Container>
        </Stack>
      </Paper>
    </Container>
  );
}
