import { ConfigurationContext, NotificationsContext } from '@kirz/mui-admin';
import { Box } from '@mui/material';
import React, {
  createRef,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { useDealStageManager } from 'hooks/useDealStageManager';
import { FunnelStage } from 'types/entities';

import DealsBoardColumn, { DealsBoardColumnRef } from './DealsBoardColumn';

export default function DealsBoard(props: {
  stagesFilter?: Record<string, any>;
  dealsFilter?: Record<string, any>;
  spacing?: number;
}) {
  const { stagesFilter, dealsFilter, spacing = 2 } = props;
  const { hasura } = useContext(ConfigurationContext);
  const { showAlert, showPrompt } = useContext(NotificationsContext);
  const { validateDealStageChange } = useDealStageManager();
  const [stages, setStages] = useState<FunnelStage[]>();
  const stageControlRefs = useRef<Record<number, Ref<DealsBoardColumnRef>>>({});

  const loadStages = useCallback(async () => {
    if (!stagesFilter) {
      return;
    }

    const items: FunnelStage[] = await hasura.request({
      type: 'query',
      source: 'funnelStage',
      selection: 'id name type',
      where: stagesFilter,
      orderBy: { sort: 'ASC' },
    });

    stageControlRefs.current = Object.fromEntries(
      items.map((item) => [item.id, createRef<DealsBoardColumnRef>()]),
    );

    setStages(items);
  }, [hasura, stagesFilter]);

  const onDragEnd = useCallback(
    async (info: DropResult) => {
      if (!stages || !info.source || !info.destination) {
        return;
      }

      const sourceStage = stages.find(
        (x) => x.id.toString() === info.source.droppableId,
      );
      const destinationStage = stages.find(
        (x) => x.id.toString() === info.destination!.droppableId,
      );
      const sourceController = stageControlRefs.current[
        sourceStage!.id.toString() as any
        // @ts-ignore
      ]!.current as DealsBoardColumnRef;
      const destinationController = stageControlRefs.current[
        destinationStage!.id.toString() as any
        // @ts-ignore
      ]!.current as DealsBoardColumnRef;

      if (sourceStage === destinationStage) {
        sourceController.swapDeals(info.source.index, info.destination!.index);
      } else {
        const deal = sourceController.getDeal(info.source.index);

        const validationResult = await validateDealStageChange(
          deal.id,
          sourceStage!.type,
          destinationStage!.type,
        );

        if (!validationResult) {
          return;
        }

        await Promise.all(
          validationResult.updates.map((x) => hasura.request(x)),
        );

        const removedDeal = sourceController.removeDeal(info.source.index);
        destinationController.addDeal(removedDeal, info.destination!.index);
      }
    },
    [stages, showAlert, showPrompt, hasura],
  );

  useEffect(() => {
    loadStages();
  }, [loadStages]);

  const isInitialized = stagesFilter && dealsFilter && stages;

  const resultStages = isInitialized
    ? stages
    : [...new Array(3).keys()].map(
        (stageId) =>
          ({
            id: stageId,
            name: '',
            type: 'skeleton',
          } as any),
      );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          overflowX: 'auto',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexWrap: 'nowrap',
            mx: spacing / 2,
          }}
        >
          {resultStages.map((stage) => (
            <Box sx={{ mx: spacing / 2, mb: spacing }} key={stage.id}>
              <DealsBoardColumn
                ref={stageControlRefs.current[stage.id]}
                stage={stage}
                dealsFilter={dealsFilter}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </DragDropContext>
  );
}
