import {
  FormInput,
  TimeInput,
  FormDialog,
  FormDialogProps,
  Grid,
} from '@kirz/mui-admin';
import { Alert, InputAdornment } from '@mui/material';
import React, { ReactNode } from 'react';

import { useAppState } from 'hooks/useAppState';
import { useTranslation } from 'hooks/useTranslation';

export function EditChannelDialog({
  slots,
  ...props
}: Partial<FormDialogProps> & {
  open: boolean;
  onClose: () => void;
  slots?: { preForm?: ReactNode; postForm?: ReactNode };
}) {
  const { t } = useTranslation();
  const { selectedProjectId } = useAppState();

  return (
    <FormDialog
      {...props}
      source="channel"
      maxWidth="sm"
      autoFocus={props?.autoFocus ?? false}
      formProps={{
        defaultValues: {
          publicationDateFrom: 60,
          publicationDateTo: 120,
          allowedIntervalFrom: '07:00',
          allowedIntervalTo: '21:00',
          ...props?.formProps?.defaultValues,
        },
      }}
      formSubmitterProps={{
        preSubmit:
          props.formSubmitterProps?.preSubmit ??
          (async ({ ...item }) => {
            return {
              ...item,
              projectId: selectedProjectId,
            };
          }),
      }}
    >
      {slots?.preForm ?? null}
      <FormInput name="name" label={t('Channel name')} required sm={6} />
      <FormInput
        name="channelUsername"
        label={t('Channel username')}
        placeholder="@yourchannel"
        required
        sm={6}
      />
      {/* <FormInput name="telegramBotToken" label="Telegram bot token" required /> */}
      <FormInput
        name="publicationDateFrom"
        label={t('Publication interval (from)')}
        type="number"
        sm={6}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{t('min')}</InputAdornment>
          ),
        }}
      />
      <FormInput
        name="publicationDateTo"
        label={t('Publication interval (to)')}
        sm={6}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{t('min')}</InputAdornment>
          ),
        }}
      />
      <Grid xs={12}>
        <Alert severity="info">{t('Time in your local time zone')}</Alert>
      </Grid>
      <TimeInput
        name="allowedIntervalFrom"
        label={t('Allowed day interval (from)')}
        required
        ampm={false}
        sm={6}
      />
      <TimeInput
        name="allowedIntervalTo"
        label={t('Allowed day interval (to)')}
        required
        ampm={false}
        sm={6}
      />
      {slots?.postForm ?? null}
    </FormDialog>
  );
}
