import { autoFilter } from './autoFilter';
import { channels } from './channels';
import { contacts } from './contacts';
import { deals } from './deals';
import { funnels } from './funnels';
import { posts } from './posts';
import { projects } from './projects';
import { sources } from './sources';
import { users } from './users';

export const tutorials = {
  posts,
  sources,
  autoFilter,
  channels,
  deals,
  contacts,
  users,
  projects,
  funnels,
};
