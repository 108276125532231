import { FormInput, NotificationsContext } from '@kirz/mui-admin';
import { Box, Tooltip } from '@mui/material';
import { Editor, useCurrentEditor } from '@tiptap/react';
import React, { useContext } from 'react';

import { useTranslation } from 'hooks/useTranslation';

import { BlockquoteIcon } from './icons/blockquote';
import { BoldIcon } from './icons/bold';
import { CodeIcon } from './icons/code';
import { CodeBlockIcon } from './icons/codeblock';
import { ItalicIcon } from './icons/italic';
import { LinkIcon } from './icons/link';
import { StrikethroughIcon } from './icons/strikethrough';
import { UnderlineIcon } from './icons/underline';

type ButtonProps = {
  onClick: () => void;
  isActive: boolean;
  tooltip: string;
  hotkeys?: string[];
  editor: Editor;
  icon: string;
};

function Button({
  tooltip,
  editor,
  hotkeys,
  isActive,
  icon,

  onClick,
}: ButtonProps) {
  return (
    <Tooltip
      title={tooltip}
      arrow
      placement="bottom"
      enterDelay={500}
      leaveDelay={100}
    >
      <Box
        component="button"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          onClick();
        }}
        dangerouslySetInnerHTML={{
          __html: icon,
        }}
        sx={{
          borderRadius: '4px',
          width: '28px',
          height: '28px',
          marginX: '2px',
          padding: '2px',
          background: 'transparent',
          flex: '0 0 28px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          cursor: 'pointer',
          color: 'rgba(29, 28, 29, 0.7)',
          // opacity: 0.3,
          '& svg': {
            width: '1rem',
            height: '1rem',
          },
          ':hover': {
            // opacity: 1,
            background: 'rgba(29, 28, 29, 0.04)',
            color: 'rgb(29, 28, 29)',
          },
          ...(isActive && {
            color: 'rgba(29, 28, 29, 0.7)',
            background: 'rgba(29, 28, 29, 0.13)',
          }),
        }}
      />
    </Tooltip>
  );
}

function Separator() {
  return (
    <Box
      component="span"
      sx={{
        background: 'rgba(29, 28, 29, 0.13)',
        flexShrink: 0,
        alignSelf: 'center',
        width: '1px',
        height: '20px',
        marginX: '4px',
      }}
    />
  );
}

export function TelegramTextEditorToolbar({
  focused,
  onClick,
}: {
  focused: boolean;
  onClick: (editor: Editor) => void;
}) {
  const { showPrompt } = useContext(NotificationsContext);
  const { editor } = useCurrentEditor();
  const { t } = useTranslation();

  if (!editor) {
    return null;
  }

  return (
    <Box
      component="div"
      onClick={() => {
        onClick(editor);
      }}
      sx={{
        cursor: focused ? 'initial' : 'text',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '4px',
          backgroundColor: 'rgb(16 24 39 / 7%)',
          opacity: focused ? 1 : 0.3,
          pointerEvents: focused ? 'initial' : 'none',
        }}
      >
        <Button
          editor={editor}
          icon={BoldIcon}
          tooltip="Bold"
          isActive={editor.isActive('bold')}
          onClick={() => editor.chain().focus().toggleBold().run()}
        />
        <Button
          editor={editor}
          icon={ItalicIcon}
          tooltip="Italic"
          isActive={editor.isActive('italic')}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        />
        <Button
          editor={editor}
          icon={StrikethroughIcon}
          tooltip="Strikethrough"
          isActive={editor.isActive('strike')}
          onClick={() => editor.chain().focus().toggleStrike().run()}
        />
        <Button
          editor={editor}
          icon={UnderlineIcon}
          tooltip="Underline"
          isActive={editor.isActive('underline')}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
        />
        <Separator />
        <Button
          editor={editor}
          icon={LinkIcon}
          tooltip="Link"
          isActive={editor.isActive('link')}
          onClick={async () => {
            const result = await showPrompt({
              title: 'Add link',
              form: (
                <>
                  <FormInput name="link" label="Link" required />
                </>
              ),
              accept: t('Save'),
              cancel: t('Cancel'),
            });

            if (!result) {
              return;
            }

            editor.chain().focus().toggleLink({ href: result.link }).run();
          }}
        />
        <Separator />
        <Button
          editor={editor}
          icon={BlockquoteIcon}
          tooltip="Blockquote"
          isActive={editor.isActive('blockquote')}
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
        />
        <Separator />
        <Button
          editor={editor}
          icon={CodeIcon}
          tooltip="Code"
          isActive={editor.isActive('code')}
          onClick={() => editor.chain().focus().toggleCode().run()}
        />
        <Button
          editor={editor}
          icon={CodeBlockIcon}
          tooltip="Code block"
          isActive={editor.isActive('codeBlock')}
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        />
      </Box>
    </Box>
  );
}
