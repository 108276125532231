import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { OnboardingPage } from 'components/OnboardingPage';
import { useSettings } from 'hooks/useSettings';
import { Project } from 'types/entities';

type AppStateContextType = {
  projects: Project[];
  selectedProjectId: number;
  setSelectedProjectId: Dispatch<SetStateAction<number>>;
};

export const AppStateContext = createContext<AppStateContextType>({} as any);

export function AppStateProvider({ children }: PropsWithChildren) {
  const { projects } = useSettings();
  const [selectedProjectId, setSelectedProjectId] = useState(
    parseInt(
      localStorage.getItem('selectedProjectId') ??
        projects?.[0]?.id?.toString(),
      10,
    ),
  );

  useEffect(() => {
    if (
      !selectedProjectId ||
      !projects.find((x) => x.id === selectedProjectId)
    ) {
      setSelectedProjectId(projects[0]?.id);
    }
  }, [selectedProjectId, projects]);

  useEffect(() => {
    localStorage.setItem(
      'selectedProjectId',
      selectedProjectId ? selectedProjectId.toString() : 'null',
    );
  }, [selectedProjectId]);

  const contextData = useMemo<AppStateContextType>(
    () => ({ projects, selectedProjectId, setSelectedProjectId }),
    [projects, selectedProjectId],
  );

  if (!selectedProjectId) {
    return <OnboardingPage />;
  }

  return (
    <AppStateContext.Provider value={contextData}>
      {children}
    </AppStateContext.Provider>
  );
}
