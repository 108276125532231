import { Bold as BoldBase } from '@tiptap/extension-bold';
import { mergeAttributes } from '@tiptap/react';

export const Bold = BoldBase.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'b',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});
