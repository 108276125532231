import {
  AutocompleteInput,
  FormGetter,
  FormInput,
  FormDialog,
  FormDialogProps,
} from '@kirz/mui-admin';
import React from 'react';

import { DealTypes } from 'constants/other';

import { DealFormFunnelAutocomplete } from './DealFormFunnelAutocomplete';

export function NewDealDialog(
  props: Partial<FormDialogProps> & {
    disableClient?: boolean;
  },
) {
  const { disableClient, ...rest } = props;

  return (
    <FormDialog
      {...(rest as any)}
      source={rest.source ?? 'deal'}
      title={rest.title ?? 'New deal'}
      maxWidth={rest.maxWidth ?? 'sm'}
    >
      <FormInput name="name" label="Name" />
      <AutocompleteInput
        name="type"
        label="Type"
        options={DealTypes}
        required
      />
      <AutocompleteInput
        label="Project"
        name="projectId"
        mode="hasura"
        source="project"
        selection="id name"
        itemText="name"
        itemValue="id"
        required
      />
      <AutocompleteInput
        label="Contact"
        name="clientId"
        mode="hasura"
        source="client"
        selection="id fullName"
        itemText="fullName"
        itemValue="id"
        required
      />
      <DealFormFunnelAutocomplete />

      <FormGetter
        names={['funnelId']}
        render={({ funnelId }) => (
          <AutocompleteInput
            label="Funnel Stage"
            name="funnelStageId"
            mode="hasura"
            source="funnelStage"
            selection="id name"
            itemText="name"
            required
            disabled={!funnelId}
            disableFetch={!funnelId}
            orderBy={[{ sort: 'ASC' }]}
            filter={{
              funnelId: { _eq: funnelId },
            }}
          />
        )}
      />
    </FormDialog>
  );
}
