import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Button } from '@mui/material';
import { TextBoxPlusOutline } from 'mdi-material-ui';
import React, { useMemo, useRef } from 'react';

import { EditSourceDialog } from 'components/EditSourceDialog';
import { TutorialWrapper } from 'components/TutorialWrapper';
import { SourceTypes } from 'constants/other';
import { useAppState } from 'hooks/useAppState';
import { useTranslation } from 'hooks/useTranslation';

export function Sources() {
  const { selectedProjectId } = useAppState();
  const { t } = useTranslation();

  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'avatarId',
        headerName: t('Icon'),
        selector: 'avatar { id contentType name extension }',
        type: 'file',
        sortable: false,
        hideText: true,
        width: 80,
        valueGetter({ row }) {
          return row.avatar?.id;
        },
        fetchMetadata(row) {
          if (!row.avatar) {
            return null;
          }

          return row.avatar;
        },
      },
      {
        field: 'name',
        headerName: t('Name'),
        flex: 1,
      },
      {
        field: 'channelUniqueName',
        headerName: t('Username'),
        flex: 1,
      },
      {
        field: 'type',
        headerName: t('Type'),
        width: 100,
        type: 'select',
        items: SourceTypes,
      },
      { field: 'createdAt', headerName: t('Created at'), type: 'date' },
    ],
    [],
  );

  return (
    <TutorialWrapper tutorial="sources">
      <TablePageLayout
        title={t('Sources')}
        actionContent={
          <Button
            sx={{ ml: 'auto' }}
            variant="contained"
            startIcon={<TextBoxPlusOutline />}
            onClick={async () => {
              tableRef.current?.openFormDialog();
            }}
          >
            {t('Add source')}
          </Button>
        }
      >
        <DataTableEx
          id="sources-table"
          ref={tableRef}
          source="source"
          columns={columns}
          disableRemovedFilter
          sortBy={{ field: 'id', sort: 'desc' }}
          persistStateMode="query"
          formTitle={(isNew) => (isNew ? t('New source') : t('Edit source'))}
          formDialogProps={{
            entityIdResolver(entity) {
              return {
                id: { _eq: entity.id },
                projectId: { _eq: selectedProjectId },
              };
            },
          }}
          selectProps={{
            filter: {
              projectId: { _eq: selectedProjectId },
              type: { _neq: 'manual' },
            },
          }}
          searchFilter={{
            inputProps: {
              placeholder: t('Search by name'),
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
            }),
          }}
          getRowId={(x) => `${selectedProjectId}-${x.id}`}
          components={{
            FormDialog: EditSourceDialog,
          }}
        />
      </TablePageLayout>
    </TutorialWrapper>
  );
}
