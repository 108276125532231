import {
  buildHasuraQuery,
  ConfigurationType as MuiAdminConfigurationType,
  DefaultConfiguration as MuiFormDefaultConfiguration,
  HasuraQuery,
} from '@kirz/mui-admin';

import 'dayjs/locale/ru';
import { api } from 'services/api';

export const Configuration = (language: 'ru' | 'en') => ({
  muiAdmin: {
    ...MuiFormDefaultConfiguration,
    defaultPhoneCountry: 'RU',
    defaultLocale: 'ru',
    defaultDateFormat: 'DD.MM.YYYY',
    availableLocales: ['en', 'ru'],
    hasura: {
      ...MuiFormDefaultConfiguration.hasura,
      async request(query, options) {
        const { extractResult, ...data } = buildHasuraQuery(query);

        const { data: response } = await api.hasuraClientInstance.post(
          '',
          data,
          {
            headers: {
              ...(options?.showRemoved && {
                'X-Hasura-Removed-Entities': 'true',
              }),
            },
          },
        );

        return extractResult ? extractResult(response.data) : response.data;
      },
      subscribe(request: HasuraQuery, onNext: (value: any) => void) {
        const { extractResult, query, variables } = buildHasuraQuery(request);

        const onNextResponse = ({ data }: any) => {
          onNext(
            typeof extractResult === 'function' ? extractResult(data) : data,
          );
        };

        const unsubscribe = api.hasuraWsClientInstance!.subscribe(
          {
            query,
            variables,
          },
          {
            next: onNextResponse,
            error: () => {},
            complete: () => {},
          },
        );

        return unsubscribe;
      },
      primaryKey: 'id',
    },
    rest: {
      ...MuiFormDefaultConfiguration.rest,
      client: api.instance,
    },
    ...(language === 'ru' && {
      translations: {
        valueRequired: 'Требуется значение',
        wrongEmailFormat: 'Неверный формат',
        wrongPhoneFormat: 'Неверный формат',
        wrongDateFormat: 'Неверный формат',
        autocompletePlaceholder: 'Введите и нажмите «Enter»',
        tableSearchPlaceholder: 'Поиск по названию',
        tableAddFilterTooltip: 'Добавить фильтр',
        tableNoRows: 'Нет данных',
        unexpectedError: 'Произошла ошибка',
        deleteTableRow: 'Удалить',
        attachmentsZoneTitle: 'Выберите тип вложения',
        attachmentsZoneLabel: 'Тип вложения',
        attachmentsZoneAccept: 'Загрузить',
        attachmentsZoneCancel: 'Отмена',
        attachmentsUploadedSuccessfully: 'Файлы загружены',
        attachmentsUploadedOrDropFileHere: 'Перетащите или выберите файл',
        attachmentsNoFiles: 'Нет загруженных файлов',
        attachmentsFileMenuDownload: 'Скачать',
        attachmentsFileMenuDelete: 'Удалить',
        addNewAutocompleteValue: 'Добавить',
        cancel: 'Отмена',
        addNew: 'Добавить',
        copied: 'Скопировано',
        create: 'Создать',
        open: 'Открыть',
        save: 'Сохранить',
        saveAndExit: 'Сохранить и выйти',
        nullSelectOptionText: 'None',
        changesNotSaved: 'Изменения не сохранены!',
        youWillLoseAllUnsavedData:
          'При выходе вы потеряете все несохраненные данные.',
        goAnyways: 'Перейти',
        clear: 'Очистить',
        noFile: 'Отсутствует',
        notSet: 'Отсутствует',
        columnFillAvailableSpace: 'Заполнить свободное пространство',
        resetTableState: 'Сбросить к значениям по умолчанию',
      },
    }),
  } as MuiAdminConfigurationType,
});
