export const FunnelStageType = {
  IN_PROGRESS: 'in_progress',
  SUCCESS: 'success',
  DECLINED: 'declined',
  OTHER: 'other',
};

export const FunnelStageSystemTypes = [
  FunnelStageType.IN_PROGRESS,
  FunnelStageType.SUCCESS,
  FunnelStageType.DECLINED,
];

export const FunnelStageTypes = [
  { value: FunnelStageType.IN_PROGRESS, text: 'In Progress' },
  { value: FunnelStageType.SUCCESS, text: 'Success' },
  { value: FunnelStageType.DECLINED, text: 'Declined' },
  { value: FunnelStageType.OTHER, text: 'Other' },
];
