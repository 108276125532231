import { Paragraph as ParagraphBase } from '@tiptap/extension-paragraph';
import { mergeAttributes } from '@tiptap/react';

export const Paragraph = ParagraphBase.extend({
  // parseHTML() {
  //   return [{ tag: 'div' }];
  // },
  // renderHTML({ HTMLAttributes }) {
  //   return [
  //     'div',
  //     mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
  //     0,
  //   ];
  // },
});
