import { Box, Typography } from '@mui/material';
import React from 'react';

import Icon5 from 'assets/icons/autofilterIcon.svg';

import { TutorialDefinition } from '../types';

export const autoFilter = (({ t, language }) => ({
  steps: [
    {
      title: '',
      text: (
        <Box sx={{ display: 'grid' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component="img"
              src={Icon5}
              sx={{ width: '50px', height: '50px' }}
            />
            <Typography
              sx={{ fontSize: '2em', fontWeight: 'bold', color: '#10b981' }}
            >
              &nbsp;&nbsp;Авто-фильтр
            </Typography>
          </Box>

          <br />
          <Typography component="span">
            Авто-фильтры используются для обработки постов в очереди
            <br />
            Это очень удобно, если вы загружаете большоое количество постов из
            разных источников
          </Typography>
          <br />
          <Typography component="span">
            Платформа может осуществлть фильтрацию по следующим признакам:
            <ul className="tutorial-ul">
              <li>слишком короткие посты</li>
              <li>слишком старые посты</li>
              <li>посты, содержащие стоп-слова</li>
              <li>посты на определенные темы (AI анализ)</li>
            </ul>
          </Typography>
        </Box>
      ),
    },
  ],
  lastStepButtonText: t('Try it out!'),
})) as TutorialDefinition;
