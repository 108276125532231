import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Button } from '@mui/material';
import { TextBoxPlusOutline } from 'mdi-material-ui';
import React, { useMemo, useRef } from 'react';

import { EditChannelDialog } from 'components/EditChannelDialog';
import { TutorialWrapper } from 'components/TutorialWrapper';
import { useAppState } from 'hooks/useAppState';
import { useTranslation } from 'hooks/useTranslation';

export function Channels() {
  const { selectedProjectId } = useAppState();
  const { t } = useTranslation();

  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'name',
        headerName: t('Name'),
        flex: 1,
      },
      {
        field: 'channelUsername',
        headerName: t('Channel'),
        flex: 1,
      },
      {
        field: 'publicationDateFrom',
        headerName: t('Publication interval (from)'),
        flex: 1,
      },
      {
        field: 'publicationDateTo',
        headerName: t('Publication interval (to)'),
        flex: 1,
      },
      {
        field: 'allowedIntervalFrom',
        headerName: t('Allowed day interval (from)'),
        flex: 1,
      },
      {
        field: 'allowedIntervalTo',
        headerName: t('Allowed day interval (to)'),
        flex: 1,
      },
      { field: 'createdAt', headerName: t('Created at'), type: 'date' },
    ],
    [],
  );

  return (
    <TutorialWrapper tutorial="channels">
      <TablePageLayout
        title={t('Channels')}
        actionContent={
          <Button
            sx={{ ml: 'auto' }}
            variant="contained"
            startIcon={<TextBoxPlusOutline />}
            onClick={async () => {
              tableRef.current?.openFormDialog();
            }}
          >
            {t('Add channel')}
          </Button>
        }
      >
        <DataTableEx
          id="channels-table"
          ref={tableRef}
          source="channel"
          columns={columns}
          disableRemovedFilter
          sortBy={{ field: 'id', sort: 'desc' }}
          persistStateMode="query"
          formTitle={(isNew) => (isNew ? t('New channel') : t('Edit channel'))}
          formDialogProps={{
            entityIdResolver(entity) {
              return {
                id: { _eq: entity.id },
                projectId: { _eq: selectedProjectId },
              };
            },
            formSubmitterProps: {
              async preSubmit({ ...item }) {
                return {
                  ...item,
                  projectId: selectedProjectId,
                };
              },
            },
          }}
          selectProps={{
            filter: {
              projectId: { _eq: selectedProjectId },
            },
          }}
          searchFilter={{
            inputProps: {
              placeholder: t('Search by name'),
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
            }),
          }}
          components={{
            FormDialog: EditChannelDialog,
          }}
        />
      </TablePageLayout>
    </TutorialWrapper>
  );
}
