import { ConfigurationContext } from '@kirz/mui-admin';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from './UserContext';

type TutorialContextType = {
  tutorials: Record<string, boolean> | null;
  completeTutorial: (tutorial: string, reloadPage?: boolean) => Promise<void>;
  resetTutorial: (tutorial: string) => Promise<void>;
};

export const TutorialContext = createContext<TutorialContextType>({} as any);

export function TutorialProvider({ children }: PropsWithChildren) {
  const { hasura } = useContext(ConfigurationContext);
  const { user } = useContext(UserContext);
  const [tutorials, setTutorials] = useState<Record<string, boolean> | null>(
    null,
  );
  const navigate = useNavigate();

  const completeTutorial = useCallback(
    async (tutorial: string, value: boolean) => {
      if (!user?.companyId) {
        return;
      }

      const newTutorialsState = { ...tutorials, [tutorial]: value };
      setTutorials(newTutorialsState);

      await hasura.request({
        type: 'mutation',
        action: 'update',
        source: 'company',
        where: { id: { _eq: user.companyId } },
        set: { tutorial: newTutorialsState },
      });
    },
    [tutorials, user?.companyId],
  );

  useEffect(() => {
    if (!user?.companyId) {
      return;
    }

    (async () => {
      const [data] = await hasura.request({
        type: 'query',
        source: 'company',
        selection: 'tutorial',
        where: { id: { _eq: user.companyId } },
        limit: 1,
      });

      setTutorials(data.tutorial);
    })();
  }, [user?.companyId]);

  const contextData = useMemo<TutorialContextType>(
    () => ({
      tutorials,
      completeTutorial: async (tutorial, reloadPage) => {
        await completeTutorial(tutorial, true);

        if (reloadPage) {
          navigate(0);
        }
      },
      resetTutorial: (tutorial) => completeTutorial(tutorial, false),
    }),
    [tutorials],
  );

  return (
    <TutorialContext.Provider value={contextData}>
      {children}
    </TutorialContext.Provider>
  );
}
