import { FormInput, FormPageLayout, Grid } from '@kirz/mui-admin';
import { ContactsOutline } from 'mdi-material-ui';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { HideProjectSelector } from 'layouts/HideProjectSelector';

export function Contact() {
  const { id: contactId } = useParams() as Record<string, string>;

  return (
    <HideProjectSelector>
      <FormPageLayout
        source="client"
        getEntityId={useCallback(
          () => ({
            id: { _eq: parseInt(contactId, 10) },
          }),
          [],
        )}
        breadcrumbs={[
          { text: 'Contacts', icon: ContactsOutline, href: '/contacts' },
          (item) => {
            return {
              text: `#${item.id} ${item.firstName ?? ''} ${item.lastName ?? ''
              }`.trim(),
              copyOnClick: true,
            };
          },
        ]}
        defaultRoute="/contacts"
      >
        <Grid container sx={{ pt: 2, px: { xs: 0, md: 2 } }}>
          <FormInput name="userUniqueName" label="Username" md={4} required />
          <FormInput name="firstName" label="First name" md={4} />
          <FormInput name="lastName" label="Last name" md={4} />
          <FormInput name="middleName" label="Middle name" md={4} />
        </Grid>
      </FormPageLayout>
    </HideProjectSelector>
  );
}
