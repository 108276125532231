import { useCallback } from 'react';
import { useTranslation as useReactI18NextTranslation } from 'react-i18next';

export const useTranslation = () => {
  const { t, i18n } = useReactI18NextTranslation();

  const switchLanguage = useCallback(
    (lang: 'en' | 'ru') => {
      window.localStorage.setItem('lang', lang);
      i18n.changeLanguage(lang);
    },
    [i18n],
  );

  const toggleLanguage = useCallback(() => {
    const newLang = i18n.language === 'en' ? 'ru' : 'en';

    window.localStorage.setItem('lang', newLang);
    i18n.changeLanguage(newLang);
  }, [i18n]);

  return { t, switchLanguage, toggleLanguage, language: i18n.language };
};
