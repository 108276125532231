import { Box } from '@mui/material';
import { Blockquote } from '@tiptap/extension-blockquote';
import { Code } from '@tiptap/extension-code';
import { CodeBlock } from '@tiptap/extension-code-block';
import { Document } from '@tiptap/extension-document';
import { Gapcursor } from '@tiptap/extension-gapcursor';
import { History } from '@tiptap/extension-history';
import { Link } from '@tiptap/extension-link';
import { Strike } from '@tiptap/extension-strike';
import { Text } from '@tiptap/extension-text';
import { Underline } from '@tiptap/extension-underline';
import { EditorProvider } from '@tiptap/react';
import React, { ReactNode, useMemo, useState } from 'react';

import { TelegramTextEditorToolbar } from './TelegramTextEditorToolbar';
import { Bold } from './extensions/Bold';
import { Italic } from './extensions/Italic';
import { Paragraph } from './extensions/Paragraph';
import { LinkMenu } from './menu/LinkMenu';

export type TelegramTextEditorProps = {
  content: string;
  onChange?: (value: string) => void;
  onSelectionChange?: (value: string) => void;
  editable?: boolean;
  slotBefore?: ReactNode;
};

export function TelegramTextEditor({
  content,
  onChange,
  onSelectionChange,
  editable = true,
  slotBefore,
}: TelegramTextEditorProps) {
  const [isFocused, setIsFocused] = useState(false);

  const extensions = useMemo(
    () => [
      Document,
      Gapcursor,
      History,
      Text,
      Paragraph,
      Bold,
      Italic,
      Underline,
      Strike,
      Link.configure({
        openOnClick: !editable,
      }),
      Code,
      CodeBlock,
      Blockquote,
    ],
    [editable],
  );

  if (!content) {
    return <Box component="div" className="telegram-text-editor" />;
  }

  return (
    <Box
      tabIndex={0}
      component="div"
      className="telegram-text-editor"
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
    >
      <EditorProvider
        slotBefore={
          slotBefore ??
          (editable && (
            <TelegramTextEditorToolbar
              focused={isFocused}
              onClick={(editor) => {
                setIsFocused(true);
                editor.chain().focus();
              }}
            />
          ))
        }
        extensions={extensions}
        content={content}
        onUpdate={({ editor }) => {
          onChange?.(editor.getHTML());
        }}
        {...(editable === false && {
          editable: false,
        })}
        {...(onSelectionChange && {
          onSelectionUpdate({ editor, transaction }) {
            onSelectionChange(
              editor.state.doc.textBetween(
                transaction.selection.from,
                transaction.selection.to,
                '',
              ),
            );
          },
        })}
      >
        {editable && <LinkMenu />}
      </EditorProvider>
    </Box>
  );
}
