import CssBaseline from '@mui/material/CssBaseline';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { LanguageProvider } from 'contexts/LanguageContext';
import { PostsCounterProvider } from 'contexts/PostsCounterContext';
import { TutorialProvider } from 'contexts/TutorialContext';
import { UserContextProvider } from 'contexts/UserContext';
import { ThemeProvider } from 'theme/ThemeProvider';

import App from './App';
import 'assets/css/site.css';
import 'i18n/config';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(relativeTime);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <BrowserRouter>
    <LanguageProvider>
      <UserContextProvider>
        <ThemeProvider>
          <TutorialProvider>
            <PostsCounterProvider>
              <CssBaseline />
              <App />
            </PostsCounterProvider>
          </TutorialProvider>
        </ThemeProvider>
      </UserContextProvider>
    </LanguageProvider>
  </BrowserRouter>,
);
