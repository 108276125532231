import React, { PropsWithChildren, useContext, useEffect } from 'react';

import { TutorialDialog } from 'components/TutorialDialog';
import { TutorialContext } from 'contexts/TutorialContext';
import { useTranslation } from 'hooks/useTranslation';

import { tutorials } from './tutorials';

export type TutorialWrapperProps = PropsWithChildren<{
  tutorial: keyof typeof tutorials;
}>;

export function TutorialWrapper({
  tutorial: tutorialKey,
  children,
}: TutorialWrapperProps) {
  const i18n = useTranslation();
  const tutorialData = tutorials[tutorialKey](i18n);
  const {
    tutorials: tutorialsState,
    completeTutorial,
    //    resetTutorial,
  } = useContext(TutorialContext);

  // useEffect(() => {
  //   resetTutorial(tutorialKey);
  // }, [tutorialKey]);

  return (
    <>
      {children}
      {tutorialsState && (
        <TutorialDialog
          open={!tutorialsState[tutorialKey]}
          onSkip={() => {
            completeTutorial(tutorialKey);
          }}
          onComplete={() => {
            completeTutorial(tutorialKey, !!tutorialData.actionDialog);
          }}
          {...tutorialData}
        />
      )}
    </>
  );
}
