import { Italic as ItalicBase } from '@tiptap/extension-italic';
import { mergeAttributes } from '@tiptap/react';

export const Italic = ItalicBase.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'i',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});
