import { NotificationsContext } from '@kirz/mui-admin';
import { useCallback, useContext } from 'react';

export function useDealStageManager() {
  const { showAlert, showPrompt } = useContext(NotificationsContext);

  const validateDealStageChange = useCallback(
    async (
      dealId: number,
      sourceStageType: string,
      destinationStageType: string,
    ) => {
      return {
        updates: [],
      };
    },
    [showAlert, showPrompt],
  );

  return {
    validateDealStageChange,
  };
}
