import { Box, Link, Skeleton, SxProps, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { CommentTextOutline } from 'mdi-material-ui';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { FunnelStage, FunnelStageDeal } from 'types/entities';

export default function DealsBoardItem(props: {
  deal: FunnelStageDeal;
  stage: FunnelStage;
  index: number;
  sx?: SxProps;
}) {
  const { deal, stage, index, sx } = props;
  const isInitialized = !!deal.createdAt;

  return (
    <Draggable draggableId={`${stage.id}-${deal.id}`} index={index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            ...sx,
            bgcolor: 'white',
            // border: '1px solid #dbdbdb',
            position: 'relative',
            padding: '1.0rem',
            boxShadow: '0px 2px 6px 0px rgb(100 116 139 / 20%)',
            borderRadius: 1,
            cursor: 'grab',
            lineHeight: '16px',
            userSelect: 'none',
            ...(!isInitialized && {
              pointerEvents: 'none',
            }),
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '3px',
              left: '6px',
              float: 'right',
              fontSize: '10px',
              color: '#666',
              opacity: 0.6,
            }}
          >
            {!isInitialized ? <Skeleton width={10} /> : `#${deal.id}`}
          </Box>

          <Box
            sx={{
              position: 'absolute',
              top: '3px',
              right: '6px',
              float: 'right',
              fontSize: '10px',
              color: '#666',
              opacity: 0.6,
            }}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {!isInitialized ? (
              <Skeleton width={100} />
            ) : (
              (dayjs(deal.funnelStageUpdatedAt) as any).fromNow(true)
            )}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '3px',
              right: '6px',
              float: 'right',
              fontSize: '10px',
              color: '#666',
              opacity: 0.6,
            }}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {!isInitialized ? (
              <Skeleton width={100} />
            ) : (
              deal.publicationDate &&
              dayjs(deal.publicationDate).format('DD.MM.YYYY HH:mm')
            )}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '3px',
              left: '6px',
              fontSize: '10px',
              color: '#666',
              opacity: 0.6,
            }}
          >
            <Tooltip
              title={!isInitialized ? '' : `${deal.commentsCount} comments`}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', fontSize: '11px' }}
              >
                {isInitialized && (
                  <CommentTextOutline sx={{ mr: '2px', fontSize: '10px' }} />
                )}
                {!isInitialized ? (
                  <Skeleton width={60} />
                ) : (
                  `${deal.commentsCount}`
                )}
              </Box>
            </Tooltip>
          </Box>
          <Box
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              mt: 1,
            }}
          >
            <Link
              href={!isInitialized ? '#' : `/deals/${deal.id}`}
              sx={{
                fontWeight: 600,
                lineHeight: 1.2,
                fontSize: '14px',
                color: '#303030',
                textDecoration: 'none',
                '&::hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {!isInitialized ? (
                <Skeleton width={160} />
              ) : (
                deal.name ?? `Deal #${deal.id}`
              )}
            </Link>
          </Box>
          <Box
            sx={{
              mt: 0.8,
              mb: 1,
              overflow: 'hidden',
              color: '#666',
              fontSize: '12px',
              fontWeight: 600,
              letterSpacing: '0.5px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <Link
              href={
                !isInitialized || !deal.client
                  ? '#'
                  : `/contacts/${deal.client.id}`
              }
              sx={{
                color: 'rgb(102, 102, 102)',
                textDecoration: 'none',
              }}
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {!isInitialized ? (
                <Skeleton width={100} />
              ) : !deal.client ? (
                '—'
              ) : (
                `${deal.client.fullName}`
              )}
            </Link>
          </Box>
        </Box>
      )}
    </Draggable>
  );
}
