import { useNavigate } from '@kirz/mui-admin';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Box,
  ButtonBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Cog } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';

import { UserAvatar } from 'components/UserAvatar';
import { UserContext } from 'contexts/UserContext';

export function AppBarAccount() {
  const navigate = useNavigate();
  const { logout } = useContext(UserContext);
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ textAlign: 'right', mr: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body1"
              sx={{
                color: 'text.primary',
                fontWeight: '500',
                lineHeight: 1,
                fontSize: { xs: '0.8rem', md: '1rem' },
              }}
            >
              {`${user.name} `}
            </Typography>
            <small>&nbsp;&nbsp;{`(#${user.companyId}/${user.id})`}</small>
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              fontWeight: '500',
              lineHeight: 1,
              mt: '4px',
              fontSize: { xs: '0.7rem', md: '0.875rem' },
            }}
          >
            {user.companyName}
          </Typography>
        </Box>
        <ButtonBase
          color="inherit"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <UserAvatar
            fullName={user.name}
            color="rgb(17, 24, 39)"
            tooltip={false}
          />
        </ButtonBase>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            navigate('/settings');
          }}
        >
          <ListItemIcon>
            <Cog fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
