import { FlagIcon } from '@kirz/mui-admin';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import React from 'react';

import { Languages } from 'constants/i18n';
import { useTranslation } from 'hooks/useTranslation';

type Props = {
  sx: BoxProps['sx'];
};

export function LanguageToggler({ sx }: Props) {
  const { language, toggleLanguage } = useTranslation();

  return (
    <Stack
      direction="row"
      sx={[
        {
          cursor: 'pointer',
          userSelect: 'none',
          '&:hover': {
            '& span': {
              color: 'black',
            },
          },
        },
        sx as any,
      ]}
      alignItems="center"
      spacing={0.5}
      component="div"
      onClick={toggleLanguage}
    >
      <Box
        sx={{
          border: 'thin solid #e8e8e8',
          borderRadius: '2px',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <FlagIcon isoCode={language === 'en' ? 'GB' : language.toUpperCase()} />
      </Box>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        {Languages.find((x) => x.value === language)?.text}
      </Typography>
    </Stack>
  );
}
