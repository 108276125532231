import {
  AutocompleteInput,
  ConfigurationContext,
  Form,
  FormGetter,
  FormSetter,
  HasuraDataTableCustomFilter,
  RowsFilter,
  useNavigate,
} from '@kirz/mui-admin';
import Grid from '@mui/material/Unstable_Grid2';
import React, {
  Ref,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { NewDealDialog } from 'components/NewDealDialog';

export default function DealsFilter(props: {
  mode: 'funnel' | 'report';
  controlRef: Ref<any>;
  hideFunnelFilter?: boolean;
  additionalFilters?: HasuraDataTableCustomFilter[];
  onStagesFilterChange: (filter: Record<string, any>) => void;
  onDealsFilterChange: (filter: Record<string, any>) => void;
}) {
  const navigate = useNavigate();
  const { hasura, onSearch } = useContext(ConfigurationContext);
  const [isNewDealDialogOpened, setIsNewDealDialogOpened] = useState(false);
  const [lastAddedDealDate, setLastAddedDealDate] = useState<number>();
  const {
    mode,
    controlRef,
    hideFunnelFilter,
    additionalFilters = [],
    onStagesFilterChange,
    onDealsFilterChange,
  } = props;
  const [filtersState, setFiltersState] = useState<{
    funnelId?: number;
    search?: string;
    filters?: Record<string, any>;
  }>({});

  const showAllDeals = mode === 'funnel' || mode === 'report';

  const filters = useMemo<HasuraDataTableCustomFilter[]>(
    () => [
      {
        type: 'date',
        name: 'Creation date (from)',
        field: 'from',
        filter: (value) => ({ createdAt: { _gte: value } }),
      },
      {
        type: 'date',
        name: 'Creation date (to)',
        field: 'to',
        filter: (value) => ({ createdAt: { _lt: value } }),
      },
      ...additionalFilters,
    ],
    [],
  );

  const stagesFilter = useMemo(() => {
    if (!filtersState.funnelId) {
      return null;
    }

    return { funnelId: { _eq: filtersState.funnelId } };
  }, [filtersState.funnelId]);

  const dealsFilter = useMemo(() => {
    if (!filtersState.funnelId && !hideFunnelFilter) {
      return null;
    }

    const searchFilters =
      filtersState.search && filtersState.search.length > 1
        ? (() => {
            const searches = onSearch(filtersState.search!);
            return {
              _or: searches.map((search) => ({
                _or: [
                  ...(Number.isInteger(parseInt(search, 10)) &&
                  search.length < 7
                    ? [{ id: { _eq: parseInt(search, 10) } }]
                    : []),
                  { name: { _ilike: `%${search}%` } },
                ],
              })),
            };
          })()
        : null;

    const customFilters =
      filtersState.filters &&
      Object.entries(filtersState.filters).map(([key, value]) => {
        const [filterIdx] = key.split('_').map((x) => parseInt(x, 10));
        const filter = filters[filterIdx];

        if (filter?.filter) {
          return filter?.filter(value);
        }

        if (value === null || value === undefined || value === '') {
          return {};
        }

        return {
          [filter.field]: {
            _eq: typeof value === 'string' ? value.trim() : value,
          },
        };
      });

    return {
      _and: [
        ...(!hideFunnelFilter
          ? [{ funnelId: { _eq: filtersState.funnelId } }]
          : []),
        ...(searchFilters ? [searchFilters] : []),
        ...(customFilters || []),
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hideFunnelFilter,
    filtersState,
    onSearch,
    filters,
    lastAddedDealDate,
    showAllDeals,
  ]);

  useEffect(() => {
    if (!stagesFilter) {
      return;
    }

    onStagesFilterChange(stagesFilter);
  }, [stagesFilter, onStagesFilterChange]);

  useEffect(() => {
    if (!dealsFilter) {
      return;
    }

    onDealsFilterChange(dealsFilter);
  }, [dealsFilter, onDealsFilterChange]);

  useImperativeHandle(
    controlRef,
    () => ({
      openNewDealDialog: () => {
        setIsNewDealDialogOpened(true);
      },
    }),
    [],
  );

  return (
    <Form
      dense
      persistStateMode={{ type: 'query', queryPrefix: 'df_', json: true }}
      formProps={
        {
          component: 'div',
        } as any
      }
    >
      <Grid xs={12}>
        <FormGetter
          names={['funnelId', 'search', 'filters']}
          onChange={(values) => {
            setFiltersState(values);
          }}
          render={(values) => (
            <FormSetter
              render={(setValue) => (
                <RowsFilter
                  initialState={{
                    search: values.search || '',
                    filters: values.filters || {},
                    tab: '0',
                  }}
                  onChange={(state) => {
                    setValue('search', state.search);
                    setValue('filters', state.filters);
                  }}
                  searchFilter={{
                    inputProps: {
                      placeholder: 'Search by ID, name or client',
                    },
                  }}
                  customFilter={{
                    filters,
                  }}
                  slots={{
                    beforeSearch: !hideFunnelFilter && (
                      <AutocompleteInput
                        label="Funnel"
                        name="funnelId"
                        mode="hasura"
                        source="funnel"
                        selection="id name stages(orderBy:{sort: ASC}, limit: 1) {id}"
                        itemText="name"
                        disableClearable
                        grid={false}
                        orderBy={{
                          sort: 'ASC',
                        }}
                        sx={{ minWidth: '340px', width: '340px', mr: 2 }}
                        onFetch={(items) => {
                          if (!values.funnelId && items?.length) {
                            setValue('funnelId', items[0].id);
                          }

                          return items;
                        }}
                      />
                    ),
                  }}
                />
              )}
            />
          )}
        />
      </Grid>
      <NewDealDialog
        open={isNewDealDialogOpened}
        onClose={() => {
          setIsNewDealDialogOpened(false);
        }}
        formProps={{
          ...(!hideFunnelFilter && {
            defaultValues: {
              funnelId: filtersState?.funnelId ?? null,
              type: 'sell',
            },
          }),
        }}
        formSubmitterProps={{
          selection: ['funnelId', 'id'],
          onSubmit: (item) => {
            if (item.funnelId === filtersState?.funnelId) {
              setLastAddedDealDate(new Date().valueOf());
            }

            navigate(`/deals/${item.id}`);
          },
          preSubmit: async (item) => {
            const [firstDeal] = await hasura.request({
              source: 'deal',
              where: {
                funnelStageId: { _eq: item.funnelStageId },
                funnelId: { _eq: item.funnelId },
              },
              selection: 'sort',
              orderBy: { sort: 'ASC' },
              limit: 1,
            });

            return {
              ...item,
              sort: firstDeal ? firstDeal.sort - 1 : 1,
            };
          },
        }}
      />
    </Form>
  );
}
