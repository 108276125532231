import { ConfigurationContext } from '@kirz/mui-admin';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  CircularProgress,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { CardsOutline } from 'mdi-material-ui';
import React, {
  forwardRef,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { FunnelStage, FunnelStageDeal } from 'types/entities';

import DealsBoardItem from './DealsBoardItem';

export type DealsBoardColumnRef = {
  getDeal: (index: number) => FunnelStageDeal;
  addDeal: (deal: FunnelStageDeal, index: number) => void;
  removeDeal: (index: number) => FunnelStageDeal;
  swapDeals: (from: number, to: number) => void;
};

const DealsBoardColumn = forwardRef(
  (
    props: {
      stage: FunnelStage;
      dealsFilter?: Record<string, any>;
      itemsPerPage?: number;
      spacing?: number;
    },
    ref: Ref<DealsBoardColumnRef>,
  ) => {
    const { stage, dealsFilter, itemsPerPage = 10, spacing = 1 } = props;
    const { hasura } = useContext(ConfigurationContext);
    const [deals, setDeals] = useState<FunnelStageDeal[]>();
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const [isFetching, setIsFetching] = useState(false);
    const isFinished = useRef(false);

    const sortedDeals = useMemo(
      () => [...(deals || [])].sort((a, b) => a.sort - b.sort),
      [deals],
    );

    const fetchDeals = useCallback(async () => {
      if (!dealsFilter) {
        return;
      }

      setIsFetching(true);

      const { items, totalItems } = await hasura.request({
        type: 'custom',
        query: `
        query Fetch($where: DealBoolExp, $limit: Int!) {
          items: deal(where: $where, orderBy: {sort: ASC}, limit: $limit) {
            id
            name
            sort
            funnelStageUpdatedAt
            
            publicationDate
            createdAt
            client {id fullName}
          }

          totalItems: dealAggregate(where: $where) {
            aggregate { count }
          }
        }
      `,
        variables: {
          where: {
            _and: [dealsFilter, { funnelStageId: { _eq: stage.id } }],
          },
          limit: (page + 1) * itemsPerPage,
        },
      });

      setDeals(
        items.map(({ ...deal }: any) => ({
          ...deal,
          commentsCount: 1,
        })),
      );
      setTotalCount(totalItems.aggregate.count);
      isFinished.current = items.length % itemsPerPage !== 0;

      setIsFetching(false);
    }, [hasura, dealsFilter, page, itemsPerPage, stage]);

    const updateDealSort = useCallback(
      async (dealId: number, sort: number, stageId?: number) => {
        await hasura.request({
          type: 'mutation',
          action: 'update',
          source: 'deal',
          where: { id: { _eq: dealId } },
          set: {
            sort,
            ...(stageId != null && {
              funnelStageId: stageId,
            }),
          },
        });
      },
      [hasura],
    );

    useEffect(() => {
      fetchDeals();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealsFilter, page]);

    useEffect(() => {
      isFinished.current = false;
    }, [dealsFilter]);

    useImperativeHandle(
      ref,
      () => ({
        getDeal(index: number) {
          if (!sortedDeals[index]) {
            throw new Error();
          }

          return sortedDeals[index]!;
        },
        addDeal(deal: FunnelStageDeal, index: number) {
          const prevDeal = sortedDeals[index];
          const nextDeal = sortedDeals[index + 1];
          const sort = (() => {
            if (!prevDeal && !nextDeal) {
              return 0;
            }

            return nextDeal
              ? (prevDeal.sort + nextDeal.sort) / 2
              : prevDeal.sort + 1;
          })();

          const lastItemSort = sortedDeals[sortedDeals.length - 1]?.sort;

          // eslint-disable-next-line no-nested-ternary
          const newItemSort =
            prevDeal?.sort != null
              ? prevDeal.sort
              : lastItemSort == null
              ? 0
              : lastItemSort + 1;
          const prevItemSort = sort;

          setDeals((items) => [
            ...(items || []).map((x) =>
              prevDeal && x.id === prevDeal.id
                ? { ...x, sort: prevItemSort }
                : x,
            ),
            {
              ...deal,
              funnelStageUpdatedAt: new Date().toISOString(),
              sort: newItemSort,
            },
          ]);

          if (prevDeal) {
            updateDealSort(prevDeal.id, prevItemSort);
          }

          updateDealSort(deal.id, newItemSort, stage.id);
          setTotalCount((x) => x + 1);
        },
        removeDeal(index: number) {
          if (!sortedDeals[index]) {
            throw new Error();
          }

          const dealId = sortedDeals[index].id;
          setDeals((items) => items!.filter((x) => x.id !== dealId));
          setTotalCount((x) => x - 1);

          return sortedDeals[index];
        },
        swapDeals(from: number, to: number) {
          const sign = from < to ? -1 : 1;

          const sourceItem = sortedDeals[from]!;
          const prevItem = sortedDeals[to]!;
          const nextItem = sortedDeals[to + sign];

          const prevItemSort = nextItem
            ? (nextItem.sort + prevItem.sort) / 2
            : prevItem.sort + sign;

          setDeals((items) =>
            items!.map((x) => {
              if (x.id === prevItem.id) {
                return { ...x, sort: prevItemSort };
              }

              if (x.id === sourceItem.id) {
                return { ...x, sort: prevItem.sort };
              }

              return x;
            }),
          );

          updateDealSort(prevItem.id, prevItemSort);
          updateDealSort(sourceItem.id, prevItem.sort);
        },
      }),
      [sortedDeals, updateDealSort, stage],
    );

    const isInitialized = stage.type !== 'skeleton' && deals && dealsFilter;

    const resultDeals = [
      ...sortedDeals,
      ...(!isInitialized
        ? [...new Array(3).keys()].map(
            (dealId) =>
              ({
                id: -new Date().valueOf() + dealId,
              } as FunnelStageDeal),
          )
        : []),
    ];

    return (
      <Box
        sx={{
          width: 340,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          // border: 'thin solid #101827',
          // bgcolor: '#10182714',
          position: 'relative',
          borderRadius: 1,
          transition: 'background-color 0.3s ease',
        }}
      >
        <Box
          sx={{
            p: 1,
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #dbdbdb',
            bgcolor: '#101827',
            color: 'white',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        >
          <Typography
            sx={{
              ml: 2,
              lineHeight: 1.2,
              fontSize: '.875rem',
              fontWeight: 600,
              flex: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {!isInitialized ? (
              <Skeleton width={140} sx={{ bgcolor: 'rgba(255,255,255,0.3)' }} />
            ) : (
              stage.name
            )}
          </Typography>
          <Tooltip title={!isInitialized ? '' : `${totalCount} deals`}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: 0.2,
                mr: 0.5,
              }}
            >
              {isInitialized && (
                <CardsOutline
                  sx={{ fontSize: '16px', mr: '2px', opacity: 0.7 }}
                />
              )}
              <Typography variant="body2">
                {!isInitialized ? (
                  <Skeleton
                    width={30}
                    sx={{ bgcolor: 'rgba(255,255,255,0.3)' }}
                  />
                ) : (
                  totalCount
                )}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
        <Droppable droppableId={stage.id.toString()}>
          {(provided, snapshot) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                height: '100%',
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                flex: '1 1 0px',
                ...(snapshot.isDraggingOver && {
                  bgcolor: '#f9f9f9',
                }),
              }}
            >
              <Box
                component={Scrollbars as any}
                autoHide
                disabled={!isInitialized}
                onScroll={({ target }: { target: any }) => {
                  if (isFinished.current) {
                    return;
                  }

                  const delta =
                    target.scrollHeight -
                    (target.scrollTop + target.clientHeight);
                  if (delta < 150) {
                    setPage(page + 1);
                    isFinished.current = true;
                  }
                }}
              >
                {resultDeals.map((deal, index) => (
                  <DealsBoardItem
                    key={deal.id}
                    deal={deal}
                    stage={stage}
                    index={index}
                    sx={{ mx: 0.7, mt: spacing }}
                  />
                ))}
                {isFetching && (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'center', py: 3 }}
                  >
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                )}
                {provided.placeholder}
              </Box>
            </Box>
          )}
        </Droppable>
      </Box>
    );
  },
);

export default DealsBoardColumn;
