import { Box, SvgIcon, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';

import LogoIcon from 'assets/icons/fav.svg';

type Props = {
  htmlColor?: string;
  sx?: SxProps<Theme>;
  onClick?: () => void;
};

export function CompanyLogo({ sx, onClick, htmlColor = 'white' }: Props) {
  return (
    <Box
      component="img"
      src={LogoIcon}
      sx={{ width: 'auto', height: 'auto', ...sx }}
      onClick={onClick}
    />
  );
}
