export type LoginPayload = {
  authToken: string;
  identityId?: string;
};

export enum LoginError {
  IDENTITY_NOT_FOUND = 1,
  WRONG_VALIDATION_CODE,
  IDENTITY_SELECTION_REQUIRED,
  PASSWORD_VALIDATION_REQUIRED,
  WRONG_PASSWORD,
}

export type LoginResponse = {
  errorCode?: LoginError;
  errorData?: any;
  success?: boolean;
};

export type UserIdentity = {
  id: string;
  userId: number;
  userName: string;
  companyId: number;
  companyName: string;
};
