import { Box, Typography } from '@mui/material';
import React from 'react';

import Icon4 from 'assets/icons/sourcesIcon.svg';

import { TutorialDefinition } from '../types';

export const sources = (({ t, language }) => ({
  steps: [
    {
      title: '',
      text: (
        <Box sx={{ display: 'grid' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component="img"
              src={Icon4}
              sx={{ width: '50px', height: '50px' }}
            />
            <Typography
              sx={{ fontSize: '2em', fontWeight: 'bold', color: '#10b981' }}
            >
              &nbsp;&nbsp;Источники
            </Typography>
          </Box>

          <br />
          <Typography component="span">
            Источники нужны для сбора информации
          </Typography>
          <br />
          <Typography component="span">
            Например, у вас новостной канал
            <br />
            Вы можете добавить несколько других каналов по интересующей вас
            тематике в Источники и настроить{' '}
            <a href="/auto-filter">правила фильтрации</a> — отбора постов
            <br />
            <br />
            Платформа будет читать все каналы-источники, обрабатывать посты и
            складывать в раздел <a href="/posts">Очередь</a>
            <br />
            Вы можете дополнительно выполнить ручную фильтрацию. А потом
            понравившиеся посты уникализировать с помощью AI.
          </Typography>
        </Box>
      ),
    },
  ],
  lastStepButtonText: t('Close'),
})) as TutorialDefinition;
