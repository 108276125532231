import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from 'react';

type PostsCounterContextType = {
  counter: number | null;
  setCounter: Dispatch<SetStateAction<number | null>>;
};

export const PostsCounterContext = createContext<PostsCounterContextType>(
  {} as any,
);

export function PostsCounterProvider({ children }: PropsWithChildren) {
  const [counter, setCounter] = useState<number | null>(null);

  const contextData = useMemo<PostsCounterContextType>(
    () => ({ counter, setCounter }),
    [counter],
  );

  return (
    <PostsCounterContext.Provider value={contextData}>
      {children}
    </PostsCounterContext.Provider>
  );
}
