import { FormDialog, Grid } from '@kirz/mui-admin';
import { Button } from '@mui/material';
import React, { ReactNode } from 'react';

import { useTranslation } from 'hooks/useTranslation';

export function wrapActionDialog({
  dialog: ActionDialog,
  topSlot,
}: {
  dialog: typeof FormDialog;
  topSlot?: ReactNode;
}) {
  return ({
    open,
    onSkip,
    onComplete,
  }: {
    open: boolean;
    onSkip: () => void;
    onComplete: () => void;
  }) => {
    const { t } = useTranslation();
    return (
      <ActionDialog
        open={open}
        dismissable={false}
        onSubmit={() => {
          onComplete();
        }}
        onClose={() => {}}
        components={{
          beforeForm: topSlot && <Grid xs={12}>{topSlot}</Grid>,
          ActionButtons({ submitButton }) {
            return (
              <>
                <Button
                  onClick={onSkip}
                  sx={{ opacity: 0.3, mr: 'auto' }}
                  color="inherit"
                  size="small"
                >
                  {t('Skip tutorial')}
                </Button>
                {submitButton}
              </>
            );
          },
        }}
        {...({} as any)}
      />
    );
  };
}
