import { Alert, Typography } from '@mui/material';
import React from 'react';

import { EditSourceDialog } from 'components/EditSourceDialog';

import { TutorialDefinition } from '../types';
import { wrapActionDialog } from '../wrapActionDialog';

export const deals = (({ t, language }) => ({
  steps: [
    {
      title: 'Look here',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
      title: 'Look there',
      text: 'Viverra vitae congue eu consequat ac felis. Tempus quam pellentesque nec nam aliquam sem et. Elementum tempus egestas sed sed risus pretium quam vulputate dignissim.',
    },
    {
      title: 'With bold text',
      text: (
        <Typography component="span">
          Viverra vitae congue eu consequat ac felis. Tempus quam pellentesque
          nec nam aliquam sem et. Elementum{' '}
          <Typography fontWeight="bold" component="span">
            tempus
          </Typography>{' '}
          egestas sed sed risus pretium quam vulputate dignissim.
        </Typography>
      ),
    },
  ],
})) as TutorialDefinition;
