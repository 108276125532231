import { Box } from '@mui/material';
import { BubbleMenu, useCurrentEditor } from '@tiptap/react';
import { TrashCan } from 'mdi-material-ui';
import React from 'react';

import { Button } from './shared/Button';
import { Separator } from './shared/Separator';

export function LinkMenu() {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <Box>
      <BubbleMenu
        tippyOptions={{ duration: 150, offset: [0, 10] }}
        editor={editor}
        shouldShow={({ editor, from, to }) => {
          return from === to && editor.isActive('link');
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            borderRadius: '4px',
            border: '1px solid rgba(29, 28, 29, 0.3)',
          }}
        >
          <Button
            label="Open link"
            onClick={() => {
              window.open(editor.getAttributes('link').href, '_blank');
            }}
          />
          <Separator />
          <Button
            label={<TrashCan sx={{ fontSize: '15px' }} />}
            color="rgb(220,0,0)"
            onClick={() => {
              editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .setTextSelection(editor.state.selection.to)
                .run();
            }}
          />
        </Box>
      </BubbleMenu>
    </Box>
  );
}
