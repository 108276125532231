import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  CheckboxInput,
  FormSubmitter,
  FormFetcher,
  Form,
  FormElementRef,
  SubmitButton,
} from '@kirz/mui-admin';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, InputAdornment, Tab, Tabs } from '@mui/material';
import { TextBoxPlusOutline } from 'mdi-material-ui';
import React, { useMemo, useRef, useState } from 'react';

import { TutorialWrapper } from 'components/TutorialWrapper';
import { useAppState } from 'hooks/useAppState';
import { useTranslation } from 'hooks/useTranslation';

export function AutoFilter() {
  const { selectedProjectId } = useAppState();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<'stopwords' | 'gpt'>(
    'stopwords',
  );

  const formRef = useRef<FormElementRef>(null);
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'value',
        headerName: t('Value'),
        flex: 1,
      },
      {
        field: 'caseSensitive',
        headerName: t('Case sensitive'),
        type: 'boolean',
        flex: 1,
      },
      {
        field: 'matchesCount',
        headerName: t('Matches'),
        flex: 1,
      },
      {
        field: 'monthMatchesCount',
        headerName: t('Matches this month'),
        flex: 1,
      },
      { field: 'createdAt', headerName: t('Created at'), type: 'date' },
    ],
    [],
  );

  const gptColumns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'text',
        headerName: t('Topic'),
        flex: 1,
      },
      { field: 'createdAt', headerName: t('Created at'), type: 'date' },
    ],
    [],
  );

  return (
    <TutorialWrapper tutorial="autoFilter">
      <TablePageLayout
        key={selectedProjectId}
        title={t('Auto Filter')}
        actionContent={
          <SubmitButton
            sx={{ ml: 'auto' }}
            variant="contained"
            loadingPosition="start"
            startIcon={<SaveIcon />}
            formRef={formRef}
            activateOnDirty
          >
            {t('Save')}
          </SubmitButton>
        }
      >
        <FormSubmitter
          source="project"
          entityId={{ id: { _eq: selectedProjectId } }}
        >
          <FormFetcher
            source="project"
            entityId={{ id: { _eq: selectedProjectId } }}
          >
            <Form ref={formRef} sx={{ pt: 1 }}>
              <FormInput
                name="minPostLength"
                type="number"
                md={3}
                label={t('Min post length')}
                placeholder={t('Filter disabled')}
                helperText={t('Small posts will be rejected')}
              />
              <FormInput
                name="postExpirationTime"
                type="number"
                md={3}
                label={t('Post expiration time')}
                placeholder={t('Filter disabled')}
                helperText={t('Old posts will be rejected')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{t('days')}</InputAdornment>
                  ),
                }}
              />
              <FormInput
                name="gptAutofilterExpectedResponse"
                label={t('[GPT Autofilter] expected response')}
                required
                disabled
                md={6}
              />
              <FormInput
                multiline
                rows={2}
                name="gptAutofilterSystemMessage"
                label={t('[GPT Autofilter] system message')}
                required
                disabled
                md={6}
                sx={{ mt: 3 }}
              />
              <FormInput
                multiline
                rows={2}
                name="gptAutofilterUserMessage"
                label={t('[GPT Autofilter] user message')}
                required
                disabled
                helperText={t(
                  '[TOPICS_LIST] and [POST_TEXT] are required placeholders',
                )}
                md={6}
                sx={{ mt: 3 }}
              />
            </Form>
          </FormFetcher>
        </FormSubmitter>
        <Tabs
          value={selectedTab}
          onChange={(e, v) => setSelectedTab(v)}
          sx={{
            mt: { xs: 4, md: 7, lg: 7 },
            mb: 2,
            minHeight: '30px',
            '& .MuiTab-root': {
              pt: 0,
              pb: 0,
              minHeight: '30px',
            },
          }}
        >
          <Tab label={t('Stopwords')} value="stopwords" />
          <Tab label={t('GPT banned topics')} value="gpt" />
        </Tabs>

        <Box
          sx={{
            minHeight: 400,
            flexGrow: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {selectedTab === 'stopwords' ? (
            <DataTableEx
              id="stopwords-table"
              ref={tableRef}
              source="stopword"
              columns={columns}
              sortBy={{ field: 'id', sort: 'desc' }}
              persistStateMode="query"
              formTitle={(isNew) =>
                isNew ? t('New stopword') : t('Edit stopword')
              }
              formDialogProps={{
                formProps: {
                  defaultValues: {
                    caseSensitive: false,
                  },
                },
                formSubmitterProps: {
                  preSubmit: (item) => ({
                    ...item,
                    projectId: selectedProjectId,
                  }),
                },
              }}
              selectProps={{
                filter: {
                  projectId: { _eq: selectedProjectId },
                },
              }}
              searchFilter={{
                inputProps: {
                  placeholder: t('Search by value'),
                },
                filter: (search) => ({
                  _or: search.flatMap((str) => [
                    { value: { _ilike: `%${str}%` } },
                  ]),
                }),
                slots: {
                  afterSearch: (
                    <Button
                      sx={{ ml: 2, flexShrink: 0 }}
                      variant="contained"
                      startIcon={<TextBoxPlusOutline />}
                      onClick={async () => {
                        tableRef.current?.openFormDialog();
                      }}
                    >
                      {t('Add stopword')}
                    </Button>
                  ),
                },
              }}
            >
              <FormInput
                name="value"
                label="Value"
                placeholder={t(
                  'Enter text you would not like to see in your detected posts',
                )}
                required
                helperText={t('Regular expression supported')}
              />
              <CheckboxInput
                name="caseSensitive"
                label={t('Case sensitive?')}
              />
            </DataTableEx>
          ) : selectedTab === 'gpt' ? (
            <DataTableEx
              id="gpt-banned-topics-table"
              ref={tableRef}
              source="gptBannedTopic"
              columns={gptColumns}
              sortBy={{ field: 'id', sort: 'desc' }}
              persistStateMode="query"
              formTitle={(isNew) =>
                isNew ? t('New banned topic') : t('Edit banned topic')
              }
              formDialogProps={{
                formSubmitterProps: {
                  preSubmit: (item) => ({
                    ...item,
                    projectId: selectedProjectId,
                  }),
                },
              }}
              selectProps={{
                filter: {
                  projectId: { _eq: selectedProjectId },
                },
              }}
              searchFilter={{
                inputProps: {
                  placeholder: t('Search by text'),
                },
                filter: (search) => ({
                  _or: search.flatMap((str) => [
                    { text: { _ilike: `%${str}%` } },
                  ]),
                }),
                slots: {
                  afterSearch: (
                    <Button
                      sx={{ ml: 2, flexShrink: 0 }}
                      variant="contained"
                      startIcon={<TextBoxPlusOutline />}
                      onClick={async () => {
                        tableRef.current?.openFormDialog();
                      }}
                    >
                      {t('Add topic')}
                    </Button>
                  ),
                },
              }}
            >
              <FormInput name="text" label={t('Topic')} required />
            </DataTableEx>
          ) : null}
        </Box>
      </TablePageLayout>
    </TutorialWrapper>
  );
}
