import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { PropsWithChildren, useContext, useMemo } from 'react';

import { UserContext } from 'contexts/UserContext';
import buildTheme from 'theme';

export function ThemeProvider({ children }: PropsWithChildren) {
  const { user } = useContext(UserContext);

  const theme = useMemo(
    () => buildTheme((user?.language ?? 'en') as any),
    [user?.language],
  );

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
