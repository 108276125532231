import {
  DataTableExRef,
  FormInput,
  FormPageLayout,
  Grid,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { FilterCogOutline } from 'mdi-material-ui';
import React, { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { FunnelStagesTable } from 'components/FunnelStagesTable';
import { HideProjectSelector } from 'layouts/HideProjectSelector';

export function Funnel() {
  const { funnelId } = useParams() as Record<string, string>;
  const tableRef = useRef<DataTableExRef>(null);

  return (
    <HideProjectSelector>
      <FormPageLayout
        source="funnel"
        getEntityId={useCallback(
          () => ({
            id: { _eq: parseInt(funnelId, 10) },
          }),
          [],
        )}
        breadcrumbs={[
          { text: 'Funnels', icon: FilterCogOutline, href: '/funnels' },
          (item) => {
            return {
              text: `#${item.id} ${item.name ?? ''}`,
              copyOnClick: true,
            };
          },
        ]}
        defaultRoute="/funnels"
      >
        <Grid container alignItems="flex-start">
          <FormInput name="name" label="Name" required />
        </Grid>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <FunnelStagesTable
            id="funnel-stages-table"
            ref={tableRef}
            funnelId={parseInt(funnelId, 10)}
            searchFilter={{
              slots: {
                beforeSearch: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle1">Stages</Typography>
                    <Button
                      startIcon={<Add />}
                      variant="outlined"
                      size="small"
                      sx={{ mx: 2, whiteSpace: 'pre' }}
                      onClick={() => {
                        tableRef.current?.openFormDialog();
                      }}
                    >
                      Add new
                    </Button>
                  </Box>
                ),
              },
              filter(search) {
                return { _and: [{ name: { _ilike: `%${search}%` } }] };
              },
            }}
            sx={{
              mt: 1,
              minHeight: 400,
              mx: -2,
              borderColor: 'transparent',
            }}
          />
        </Box>
      </FormPageLayout>
    </HideProjectSelector>
  );
}
