import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

export type ButtonProps = {
  label: ReactNode;
  color?: string;
  onClick: () => void;
};

export function Button({ label, color, onClick }: ButtonProps) {
  return (
    <Box
      component="button"
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        color: color ?? 'rgb(29, 28, 29, 0.9)',
        padding: '5px 8px',
        fontSize: '15px',
        opacity: 0.8,
        ':hover': {
          opacity: 1,
          background: 'rgba(29, 28, 29, 0.04)',
          // color: 'rgb(29, 28, 29)',
        },
      }}
    >
      {label}
    </Box>
  );
}
