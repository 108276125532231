import React, { PropsWithChildren, useEffect } from 'react';

export function HideProjectSelector({ children }: PropsWithChildren) {
  useEffect(() => {
    // @ts-ignore
    document.querySelector('#project-selector').style.display = 'none';

    return () => {
      // @ts-ignore
      document.querySelector('#project-selector').style.display = 'block';
    };
  }, []);

  return <>{children}</>;
}
