import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FormInput,
  AutocompleteInput,
  ConfigurationContext,
  FormGetter,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  FunnelStageSystemTypes,
  FunnelStageType,
  FunnelStageTypes,
} from 'constants/funnels';

export const FunnelStagesTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      funnelId: number;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { funnelId, filterColumns, ...rest } = props;
    const { hasura } = useContext(ConfigurationContext);
    const [sortValues, setSortValues] = useState<number[] | null>(null);

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'sort',
            type: 'sort',
            sortKey: 'sort',
            rowsSortValues: sortValues,
            onSortChange: async (direction, row, targetRowSort) => {
              const source = 'FunnelStage';
              const { id } = row;

              await hasura.request({
                type: 'custom',
                query: `
                  mutation ChangeSort($where1: ${source}BoolExp!, $set1: ${source}SetInput!, $where2: ${source}BoolExp!, $set2: ${source}SetInput!) {
                    result2: update${source}(where: $where2, _set: $set2) {
                      affected_rows
                    }
                    result1: update${source}(where: $where1, _set: $set1) {
                      affected_rows
                    }
                  }
                `,
                variables: {
                  where1: { id: { _eq: id }, funnelId: { _eq: funnelId } },
                  set1: { sort: targetRowSort },
                  where2: {
                    sort: { _eq: targetRowSort },
                    funnelId: { _eq: funnelId },
                  },
                  set2: { sort: row.sort },
                },
              });

              tableRef.current?.reload();
            },
          },
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
          },
          {
            field: 'type',
            headerName: 'Type',
            type: 'select',
            width: 150,
            items: FunnelStageTypes,
          },
          {
            field: 'dealsAggregate { aggregate { *count* } }',
            headerName: 'Deals count',
            flex: 1,
            type: 'relationship',
            fetchRemoved: false,
            sortable: false,
          },
          { field: 'createdAt', headerName: 'Created at', type: 'date' },
        ]),
      [filterColumns, sortValues],
    );

    const fetchSorts = useCallback(async () => {
      const items = await hasura.request({
        type: 'query',
        source: 'funnelStage',
        selection: 'sort',
        where: {
          funnelId: { _eq: funnelId },
        },
      });

      setSortValues(items.map((x: any) => x.sort));
    }, [funnelId]);

    useEffect(() => {
      fetchSorts();
    }, [fetchSorts]);

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="funnelStage"
        columns={columns}
        persistStateMode="query"
        sortBy={{ field: 'sort', sort: 'asc' }}
        editable={{
          onEdit(row) {
            tableRef.current?.openFormDialog(row);
          },
        }}
        deletable={
          rest.deletable ?? {
            isEnabled(row) {
              return (
                !row.dealsAggregate.aggregate.count &&
                !FunnelStageSystemTypes.includes(row.type)
              );
            },
            onDeleted: (row) => {
              setSortValues(sortValues!.filter((x) => x !== row.sort));
            },
          }
        }
        selectProps={{
          filter: {
            funnelId: { _eq: funnelId },
            ...props.selectProps?.filter,
          },
        }}
        formTitle={(isNew) => (isNew ? 'New stage' : 'Edit stage')}
        formDialogProps={{
          ...rest.formDialogProps,
          formSubmitterProps: {
            preSubmit(item) {
              return {
                funnelId,
                ...item,
              };
            },
            onSubmit() {
              fetchSorts();
            },
          },
          formProps: {
            defaultValues: {
              type: FunnelStageType.OTHER,
            },
          },
        }}
      >
        <FormInput name="name" label="Name" required />
        <FormGetter
          names={['type']}
          render={(x) => (
            <AutocompleteInput
              name="type"
              label="Type"
              required
              disabled={FunnelStageSystemTypes.includes(x.type)}
              options={FunnelStageTypes}
              getOptionDisabled={(option) =>
                FunnelStageSystemTypes.includes(option.value)
              }
            />
          )}
        />
      </DataTableEx>
    );
  },
);
