import {
  AutocompleteInput,
  ConfigurationContext,
  Form,
  FormElementRef,
  FormFetcher,
  FormInput,
  FormSubmitter,
  Grid,
  SelectInput,
  SubmitButton,
  FlagIcon,
  TablePageLayout,
} from '@kirz/mui-admin';
import SaveIcon from '@mui/icons-material/Save';
import { InputAdornment, Typography } from '@mui/material';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { UserContext } from 'contexts/UserContext';
import { useTranslation } from 'hooks/useTranslation';
import { HideProjectSelector } from 'layouts/HideProjectSelector';

export function Settings() {
  const { user, setUser } = useContext(UserContext);
  const formRef = useRef<FormElementRef>(null);
  const { t } = useTranslation();
  const { hasura } = useContext(ConfigurationContext);
  const [selectedLanguage, setSelectedLanguage] = useState(user.language);

  const changeLanguage = useCallback(
    async (language: string) => {
      setUser((x) => ({ ...x, language }));

      await hasura.request({
        type: 'mutation',
        source: 'user',
        action: 'update',
        where: { id: { _eq: user.id } },
        set: {
          language,
        },
      });
    },
    [user.language],
  );

  return (
    <HideProjectSelector key={user.language}>
      <TablePageLayout
        title={t('Settings')}
        actionContent={
          <SubmitButton
            sx={{ ml: 'auto' }}
            variant="contained"
            loadingPosition="start"
            startIcon={<SaveIcon />}
            formRef={formRef}
            activateOnDirty
          >
            {t('Save')}
          </SubmitButton>
        }
      >
        <FormSubmitter
          source="user"
          entityId={{ id: { _eq: user.id } }}
          selection={['language']}
          onSubmit={(item) => {
            setUser((prev) => ({ ...prev, language: item.language }));
          }}
        >
          <FormFetcher source="user" entityId={{ id: { _eq: user.id } }}>
            <Form ref={formRef} sx={{ pt: 1 }}>
              <SelectInput
                name="language"
                label={t('Language')}
                md={4}
                items={[
                  { text: 'English', value: 'en' },
                  { text: 'Русский', value: 'ru' },
                ]}
              />
            </Form>
          </FormFetcher>
        </FormSubmitter>
      </TablePageLayout>
    </HideProjectSelector>
  );
}
