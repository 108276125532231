import {
  AutocompleteInput,
  DateInput,
  FormGetter,
  FormInput,
  FormPageLayout,
  Grid,
  OpenInNewInputAdorement,
  SelectInput,
  TimeInput,
} from '@kirz/mui-admin';
import dayjs from 'dayjs';
import { Trello } from 'mdi-material-ui';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { DealFormFunnelAutocomplete } from 'components/DealFormFunnelAutocomplete';
import { DealTypes, PaymentTypes, YesOrNoTypes } from 'constants/other';
import { HideProjectSelector } from 'layouts/HideProjectSelector';

export function Deal() {
  const { id: dealId } = useParams() as Record<string, string>;

  return (
    <HideProjectSelector>
      <FormPageLayout
        source="deal"
        getEntityId={useCallback(
          () => ({
            id: { _eq: parseInt(dealId, 10) },
          }),
          [],
        )}
        breadcrumbs={[
          { text: 'Deals', icon: Trello, href: '/deals' },
          (item) => {
            return {
              text: `#${item.id} ${item.name ?? ''}`.trim(),
              copyOnClick: true,
            };
          },
        ]}
        defaultRoute="/deals"
        formFetcherProps={{
          onSelection(selections) {
            return [...selections, 'publicationDate'];
          },
          onFetch(item) {
            return {
              ...item,
              ...(item.publicationDate && {
                formPublicationDate: dayjs(item.publicationDate).format(
                  'YYYY-MM-DD',
                ),
                formPublicationTime: dayjs(item.publicationDate).format(
                  'HH:mm',
                ),
              }),
            };
          },
        }}
        formSubmitterProps={{
          preSubmit(item) {
            return {
              ...item,
              publicationDate: !item.formPublicationDate
                ? null
                : dayjs(
                    `${item.formPublicationDate}T${
                      item.formPublicationTime ?? '00:00'
                    }:00`,
                    'YYYY-MM-DDTHH:mm:ss',
                  ).toISOString(),
            };
          },
        }}
      >
        <Grid container sx={{ pt: 2, px: { xs: 0, md: 2 } }}>
          <FormInput
            name="name"
            label="Name"
            placeholder="Enter deal title (e.g. Sell ads for @durov)"
            md={4}
          />
          <DealFormFunnelAutocomplete md={4} />

          <FormGetter
            names={['funnelId']}
            render={({ funnelId }) => (
              <AutocompleteInput
                label="Funnel Stage"
                name="funnelStageId"
                mode="hasura"
                source="funnelStage"
                selection="id name"
                itemText="name"
                required
                disabled={!funnelId}
                disableFetch={!funnelId}
                orderBy={[{ sort: 'ASC' }]}
                filter={{
                  funnelId: { _eq: funnelId },
                }}
                md={4}
              />
            )}
          />
          <AutocompleteInput
            label="Contact"
            name="clientId"
            mode="hasura"
            source="client"
            selection="id fullName"
            itemText="fullName"
            itemValue="id"
            required
            inputProps={{
              InputProps: {
                startAdornment: (
                  <OpenInNewInputAdorement
                    field="clientId"
                    baseUrl="/contacts"
                  />
                ),
              },
            }}
            md={4}
          />
          <SelectInput
            name="type"
            label="Type"
            items={DealTypes}
            required
            md={4}
          />
          <AutocompleteInput
            label="Project"
            name="projectId"
            mode="hasura"
            source="project"
            selection="id name"
            itemText="name"
            itemValue="id"
            required
            md={4}
          />

          <FormInput
            name="postUrl"
            label="Post URL"
            placeholder="Enter the URL of published post"
            md={5}
            sm={6}
          />
          <AutocompleteInput
            name="paymentMethod"
            label="Payment Method"
            options={PaymentTypes}
            required
            md={3}
            sm={6}
          />
          <FormInput
            name="price"
            label="Price"
            placeholder="Publication price"
            type="number"
            md={2}
            sm={6}
          />
          <SelectInput
            name="erid"
            label="Ad marking (erid)"
            items={YesOrNoTypes}
            required
            md={2}
            sm={6}
          />
          <DateInput name="leadDate" label="Lead date" sm={6} md={3} />
          <DateInput name="removalDate" label="Removal Date" sm={6} md={3} />
          <DateInput
            name="formPublicationDate"
            label="Publication Date"
            formFetcherValueResolver={null}
            formSubmitterValueResolver={null}
            sm={6}
            md={3}
          />
          <TimeInput
            name="formPublicationTime"
            label="Publication Time"
            formFetcherValueResolver={null}
            formSubmitterValueResolver={null}
            ampm={false}
            helperText={`${
              Intl.DateTimeFormat().resolvedOptions().timeZone
            } timezone`}
            sm={6}
            md={3}
          />
        </Grid>
      </FormPageLayout>
    </HideProjectSelector>
  );
}
