import { NotificationsContext, useNavigate } from '@kirz/mui-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import React, { useContext, useState } from 'react';

import { useDelayedMemo } from 'hooks/useDelayedMemo';
import { useTranslation } from 'hooks/useTranslation';

import { TutorialDefinition } from './TutorialWrapper/types';

export type TutorialDialogProps = {
  open: boolean;
  onSkip: () => void;
  onComplete: () => void;
} & ReturnType<TutorialDefinition>;

export function TutorialDialog({
  open,
  steps,
  lastStepButtonText,
  actionDialog: ActionDialog,
  actionHref,
  onSkip,
  onComplete,
}: TutorialDialogProps) {
  const navigate = useNavigate();
  const { showConfirm } = useContext(NotificationsContext);
  const [activeStep, setActiveStep] = useState(0);
  const [isActionDialogOpened, setIsActionDialogOpened] = useState(false);

  const step = steps[activeStep];

  const { t } = useTranslation();

  const delayedOpen = !!useDelayedMemo(open, 400) && open;
  const delayedIsActionDialogOpened =
    !!useDelayedMemo(isActionDialogOpened, 300) && isActionDialogOpened;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = async () => {
    if (
      await showConfirm({
        title: t('Skip tutorial?'),
        text: t('You may miss an important information!'),
        accept: t('Yes'),
        cancel: t('No'),
      })
    ) {
      onSkip();
    }
  };

  return (
    <>
      <Dialog open={delayedOpen && !isActionDialogOpened}>
        <DialogTitle>{step.title}</DialogTitle>
        <DialogContent className="tutorial-window">
          {typeof step.text === 'string' ? (
            <DialogContentText>{step.text}</DialogContentText>
          ) : (
            step.text
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSkip}
            sx={{ opacity: 0.3, mr: 'auto' }}
            color="inherit"
            size="small"
          >
            {t('Skip tutorial')}
          </Button>
          {activeStep !== 0 && (
            <Button onClick={handleBack} color="primary">
              {t('Back')}
            </Button>
          )}
          {activeStep < steps.length - 1 ? (
            <Button onClick={handleNext} color="primary">
              {t('Next')}
            </Button>
          ) : (
            <Button
              onClick={() => {
                if (actionHref) {
                  onComplete();
                  navigate(actionHref);
                  return;
                }

                if (ActionDialog) {
                  setIsActionDialogOpened(true);
                  return;
                }

                onComplete();
              }}
              color="primary"
            >
              {lastStepButtonText ?? 'Complete'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {ActionDialog && (
        <ActionDialog
          open={delayedIsActionDialogOpened}
          onSkip={async () => {
            setIsActionDialogOpened(false);
            handleSkip();
          }}
          onComplete={() => {
            setIsActionDialogOpened(false);
            onComplete();
          }}
        />
      )}
    </>
  );
}
