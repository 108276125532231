export const SourceType = {
  TELEGRAM: 'telegram',
  // MANUAL: 'manual',
  // dont display Manual Source
};

export const SourceTypes = [
  { value: SourceType.TELEGRAM, text: 'Telegram' },
  // { value: SourceType.MANUAL, text: 'Manual' },
];

export const PostType = {
  TEXT: 'text',
  DOCUMENT: 'document',
  IMAGE: 'image',
  VIDEO: 'video',
};

export const PostTypes = [
  { value: PostType.TEXT, text: 'Text' },
  { value: PostType.DOCUMENT, text: 'Document' },
  { value: PostType.IMAGE, text: 'Image' },
  { value: PostType.VIDEO, text: 'Video' },
];

export const AdsType = {
  BUY: 'buy',
  SELL: 'sell',
};

export const DealTypes = [
  { value: AdsType.SELL, text: 'Sell' },
  { value: AdsType.BUY, text: 'Buy' },
];

export const YesOrNoType = {
  YES: 'yes',
  NO: 'no',
};

export const YesOrNoTypes = [
  { value: YesOrNoType.YES, text: 'Yes' },
  { value: YesOrNoType.NO, text: 'No' },
];

export const PaymentType = {
  ROBOKASSA: 'robokassa',
  FREE: 'free',
  INVOICE: 'invoice',
  BARTER: 'barter',
  OTHER: 'other',
};

export const PaymentTypes = [
  { value: PaymentType.ROBOKASSA, text: 'Robokassa' },
  { value: PaymentType.FREE, text: 'Free' },
  { value: PaymentType.INVOICE, text: 'Invoice' },
  { value: PaymentType.BARTER, text: 'Barter' },
  { value: PaymentType.OTHER, text: 'OTHER' },
];
