import React, { PropsWithChildren, createContext } from 'react';

import { useTranslation } from 'hooks/useTranslation';

const LanguageContext = createContext<ReturnType<typeof useTranslation> | null>(
  null,
);

export function LanguageProvider({ children }: PropsWithChildren) {
  const translation = useTranslation();

  return (
    <LanguageContext.Provider value={translation}>
      {children}
    </LanguageContext.Provider>
  );
}
