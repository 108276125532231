import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import React, { useMemo, useRef } from 'react';

import { HideProjectSelector } from 'layouts/HideProjectSelector';

export function Companies() {
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'name',
        headerName: 'Name',
      },
      { field: 'createdAt', headerName: 'Created at', type: 'date' },
    ],
    [],
  );

  return (
    <HideProjectSelector>
      <TablePageLayout title="Companies">
        <DataTableEx
          id="company-table"
          ref={tableRef}
          source="company"
          columns={columns}
          disableRemovedFilter
          sortBy={{ field: 'id', sort: 'desc' }}
          persistStateMode="query"
          searchFilter={{
            inputProps: {
              placeholder: 'Search by name',
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
            }),
          }}
        />
      </TablePageLayout>
    </HideProjectSelector>
  );
}
