import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FormInput,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

export const TextReplacersTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'id',
            headerName: 'ID',
            minWidth: 40,
            width: 60,
          },
          {
            field: 'find',
            headerName: 'Find',
            flex: 1,
          },
          {
            field: 'replace',
            headerName: 'Replace',
            flex: 1,
          },
          { field: 'createdAt', headerName: 'Created at', type: 'date' },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="textReplacer"
        columns={columns}
        persistStateMode="query"
        sortBy={rest.sortBy ?? { field: 'id', sort: 'desc' }}
        editable={{
          onEdit(row) {
            tableRef.current?.openFormDialog(row);
          },
        }}
        selectProps={{
          ...rest.selectProps,
          onSelection(selections) {
            return [...selections, 'sourceId', 'projectId'];
          },
        }}
        formTitle={(isNew) => (isNew ? 'New replacer' : 'Edit replacer')}
        formDialogProps={{
          ...rest.formDialogProps,
          formProps: {
            ...rest.formDialogProps?.formProps,
          },
          formFetcherProps: {
            ...rest.formDialogProps?.formFetcherProps,
          },
          entityIdResolver(entity) {
            return {
              id: { _eq: entity.id },
              projectId: { _eq: entity.projectId },
              sourceId: { _eq: entity.sourceId },
            };
          },
        }}
      >
        <FormInput name="find" label="Find" required multiline rows={3} />
        <FormInput name="replace" label="Replace" multiline rows={3} />
      </DataTableEx>
    );
  },
);
