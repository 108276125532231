import {
  FormInput,
  FormDialog,
  FormDialogProps,
  HiddenInput,
  FormGetter,
  SelectInput,
  NotificationsContext,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React, { ReactNode, useContext, useRef } from 'react';

import { SourceTypes } from 'constants/other';
import { useAppState } from 'hooks/useAppState';
import { useTranslation } from 'hooks/useTranslation';
import { api } from 'services/api';

import { TextReplacersTable } from './TextReplacersTable';

export function EditSourceDialog({
  ...props
}: Partial<FormDialogProps> & {
  open: boolean;
  onClose: () => void;
}) {
  const { selectedProjectId } = useAppState();
  const { showAlert } = useContext(NotificationsContext);
  const { t } = useTranslation();
  const tableRef = useRef<DataTableExRef>(null);

  return (
    <FormDialog
      {...props}
      source="source"
      maxWidth={props.maxWidth ?? 'sm'}
      formProps={{
        defaultValues: {
          type: 'telegram',
          ...props?.formProps?.defaultValues,
        },
      }}
      formSubmitterProps={{
        async preSubmit({ id, ...item }) {
          if (id) {
            return item;
          }

          const { type, channel } = item;
          const chat = await api.searchChannel(channel);

          if (!chat) {
            showAlert('Channel not found', 'error');
            return null;
          }

          const avatar =
            chat.photo?.big.id &&
            (await api.downloadFile(chat.photo.big.id, chat.tdlib_client_id));

          return {
            type,
            tgChatId: chat.id,
            avatarId: avatar?.id,
            name: chat.title,
            channelUniqueName: `@${channel
              .replace(`https://t.me/`, '')
              .replace(`http://t.me/`, '')
              .replace(/@/g, '')
              .trim()}`,
            projectId: selectedProjectId,
          };
        },
      }}
    >
      <HiddenInput name="id" />
      <FormGetter
        names={['id']}
        render={({ id: sourceId }) =>
          !sourceId ? (
            <>
              <SelectInput
                name="type"
                label="Type"
                items={SourceTypes}
                required
                formFetcherValueResolver={null}
                formSubmitterValueResolver={null}
              />
              <FormInput
                name="channel"
                label={t('Channel')}
                placeholder={t(
                  'Enter full channel username (e.g. @prodneupal)',
                )}
                required
                formFetcherValueResolver={null}
                formSubmitterValueResolver={null}
              />
            </>
          ) : (
            <>
              <FormInput name="name" label="Name" required />
              <FormInput name="channelUniqueName" label="Username" required />
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1">Text replacers</Typography>
                  <Button
                    startIcon={<Add />}
                    variant="outlined"
                    size="small"
                    sx={{ mx: 2, whiteSpace: 'pre' }}
                    onClick={() => {
                      tableRef.current?.openFormDialog();
                    }}
                  >
                    Add new
                  </Button>
                </Box>
                <TextReplacersTable
                  id="text-replacers-table"
                  ref={tableRef}
                  sx={{
                    mt: 1,
                    minHeight: 200,
                  }}
                  skeletonRowsCount={1}
                  selectProps={{
                    filter: {
                      projectId: { _eq: selectedProjectId },
                      sourceId: { _eq: sourceId },
                    },
                  }}
                  formDialogProps={{
                    formSubmitterProps: {
                      preSubmit(item) {
                        return {
                          ...item,
                          projectId: selectedProjectId,
                          sourceId,
                        };
                      },
                    },
                  }}
                />
              </Box>
            </>
          )
        }
      />
    </FormDialog>
  );
}
