import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  Form,
  FormSetter,
  FormGetter,
} from '@kirz/mui-admin';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { TextBoxPlusOutline } from 'mdi-material-ui';
import React, { useMemo, useRef, useState } from 'react';

import { NewDealDialog } from 'components/NewDealDialog';
import { HideProjectSelector } from 'layouts/HideProjectSelector';

type Props = {
  dealsFilter?: Record<string, any>;
};

export function DealsTable({ dealsFilter }: Props) {
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'client',
        headerName: 'Client',
        flex: 1,
        type: 'relationship',
        selector: 'client { *fullName* }',
      },
      {
        field: 'project',
        headerName: 'Project',
        flex: 1,
        type: 'relationship',
        selector: 'project { *name* }',
      },
      {
        field: 'funnel',
        headerName: 'Funnel',
        flex: 1,
        type: 'relationship',
        selector: 'funnel { *name* }',
      },
      {
        field: 'stage',
        headerName: 'Stage',
        flex: 1,
        type: 'relationship',
        selector: 'stage { *name* }',
      },
    ],
    [],
  );

  return (
    <DataTableEx
      id="deals-table"
      ref={tableRef}
      source="deal"
      columns={columns}
      editPageUrl="/deals"
      sortBy={{ field: 'id', sort: 'desc' }}
      persistStateMode="query"
      selectProps={{
        filter: dealsFilter,
      }}
    />
  );
}
