import { FormDialog, FormDialogProps, FormInput, Grid } from '@kirz/mui-admin';
import React, { ReactNode } from 'react';

import { useTranslation } from 'hooks/useTranslation';

export type EditProjectDialogProps = Partial<FormDialogProps> & {
  open: boolean;
  onClose: () => void;
  formHeader?: ReactNode;
};

export function EditProjectDialog({
  entityId,
  formHeader,
  ...props
}: EditProjectDialogProps) {
  const { t } = useTranslation();

  return (
    <FormDialog
      {...props}
      source="project"
      entityId={entityId}
      maxWidth="sm"
      autoFocus={false}
      title={entityId ? t('Edit project') : t('Create new project')}
    >
      {formHeader && <Grid xs={12}>{formHeader}</Grid>}
      <FormInput name="name" label={t('Project name')} required />
    </FormDialog>
  );
}
