import { createContext } from 'react';

import { Project } from 'types/entities';

export type SettingsContextType = {
  projects: Project[];
  publicationDateFrom: number;
  publicationDateTo: number;
  reloadSettings: () => Promise<void>;
};

export const SettingsContext = createContext<SettingsContextType>({} as any);
